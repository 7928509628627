import { envs } from "utils/envs";
import greenShape from "../../../theme/images/greenShape.png";
import purpleShape from "../../../theme/images/purpleShape.png";
import Avatar from "../../atoms/Avatar";
import styles from "./DocCard.module.scss";

interface Props {
  className: string;
  doc: any;
}

export const DocCard = ({ className = "", doc }: Props) => {
return (
    <div className={`${styles.docCard} ${className}`}>
      <img
        className={styles.purpleShape}
        src={purpleShape}
        alt="purple shape"
      />
      <img className={styles.greenShape} src={greenShape} alt="green shape" />
      <Avatar avatarImg={`${envs.REACT_APP_S3_ENDPOINT}/pic-${doc?.id}?t=${Date.now()}`}/>
      <div className={styles.docName}>
        {doc?.given_name} {doc?.family_name}
      </div>
      {/* <div>{doc?.title}</div> */}
    </div>
  );
};
