export const checkIf48HoursPassed = (chat: any): boolean => {
  if (chat) {
    const accountCreationDate = new Date(chat.createdAt);
    const currentDate = new Date();

    const msIn48Hours = 48 * 60 * 60 * 1000;

    return (currentDate.getTime() - accountCreationDate.getTime()) > msIn48Hours;
  }

  return false;
};
