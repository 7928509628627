export const envs = {
  REACT_APP_SERVER_URL: process.env.REACT_APP_SERVER_URL,
  REACT_APP_AUTH_SERVICE_URL: process.env.REACT_APP_AUTH_SERVICE_URL,
  REACT_APP_FRONTEND_URL: process.env.REACT_APP_FRONTEND_URL,
  REACT_APP_STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_VONAGE_API_KEY: process.env.REACT_APP_VONAGE_API_KEY,
  REACT_APP_S3_ENDPOINT: process.env.REACT_APP_S3_ENDPOINT,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  REACT_APP_SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_SITE_URL: process.env.REACT_APP_SITE_URL,
  REACT_APP_SECRET_KEY: process.env.REACT_APP_SECRET_KEY
};
