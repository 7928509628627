import CryptoJS from 'crypto-js';
import { envs } from './envs';

const secretKey = envs.REACT_APP_SECRET_KEY;

export class LocalStorageService {

  private static encryptData(data: any): string {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    } catch (error) {
      console.error('Error encrypting data:', error);
      throw new Error('Encryption failed');
    }
  }

  private static decryptData(encryptedData: string): any {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.error('Error decrypting data:', error);
      return null;
    }
  }

  static getItem<T>(key: string): T | null {
    try {
      const encryptedData = localStorage.getItem(key);
      if (!encryptedData) return null;
      return this.decryptData(encryptedData);
    } catch (error) {
      console.error(`Error parsing localStorage key "${key}":`, error);
      return null;
    }
  }

  static setItem<T>(key: string, value: T): void {
    try {
      const encryptedData = this.encryptData(value);
      localStorage.setItem(key, encryptedData);
    } catch (error) {
      console.error(`Error saving to localStorage key "${key}":`, error);
    }
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  static clear(): void {
    localStorage.clear();
  }
}
