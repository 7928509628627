import { Fragment, useState } from "react";
import styles from "./Header.module.scss";
import logo from "../../../theme/images/logo-new.webp";
import Button from "../../atoms/Button";
import Text from "../../atoms/Text";
import {
  ChevronRightIcon,
  Bars2Icon,
  XMarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { useNavigate, useLocation } from "react-router-dom";
import { getToken } from "utils/getToken";
import routes from "routes";
import { trpc } from "utils/trpc";
import { queryClient } from "index";
import { envs } from "../../../utils/envs";
import Avatar from "../../atoms/Avatar";
import { useTherapistData } from '../../../customHooks/therapistDataContext';

const appSiteUrl = envs.REACT_APP_SITE_URL;
const appHomeUrl = envs.REACT_APP_FRONTEND_URL;

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const menuRoutes = [
    routes.AboutUs.path.split("/")[1],
    routes.OurApproach.path.split("/")[1],
    routes.Reviews.path.split("/")[1],
    routes.FaqPage.path.split("/")[1],
    routes.ContactUs.path.split("/")[1],
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAppointmentMenuOpen, setIsAppointmentMenuOpen] = useState(false);

  const accessToken = getToken('accessToken')
  const {therapistData, removeTherapistData} = useTherapistData();


  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined},
      {enabled: !!accessToken}
    );
  

  return (
    <>
      <div
        className={`${styles.header} ${
          (menuRoutes.includes(path) || location.pathname === routes.Survey.links.intro) && "landing-page-header"
        } ${
          (path === routes.Login.path.split("/")[1] ||
            location.pathname === routes.Survey.links.intro) &&
          "login-header"
        } ${
          location.pathname === routes.ChooseTherapist.path
            ? styles.chooseTherapistHeader
            : ""
        }
        
        ${location.pathname === routes.Survey.links.start && "survey-header"}
        ${
          location.pathname === routes.Survey.links.intro &&
          "survey-header-start"
        }
        ${
          location.pathname === routes.PatientBookingFirstAppointment.path &&
          "first-appointment-header"
        }

        `}
      >
        <img
          className={styles.logo}
          src={logo}
          alt="logo Mymentis"
          onClick={() => {
            const scrennWidth = window.innerWidth;
            if (
              location.pathname === routes.ConfirmTherapist.path &&
              scrennWidth < 750
            ) {
              return;
            }
            !(
              (location.pathname === "/questionario/incorso" || path === "") &&
              scrennWidth < 750
            ) && (window.location.href = `${accessToken ? appHomeUrl : appSiteUrl}`);
          }}
        />
        {location.pathname === routes.ChooseTherapist.path && (
          <Fragment>
            <div className={styles.chooseTherapistLogout}>
              Non è il tuo profilo?{" "}
              <span
                onClick={async () => {
                  // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                  // document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("storedChat");
                  localStorage.removeItem("greyBlock");
                  localStorage.removeItem("userId");
                  navigate(routes.Login.path);
                  queryClient.removeQueries();
                }}
              >
                Esci
              </span>
            </div>
            <div
              className={styles.chooseTherapistMenu}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {!isMenuOpen && (
                <div className={styles.menuClose}>
                  <Bars2Icon height={25}/>
                  <Text type="span" children="Menu"/>
                </div>
              )}
              {isMenuOpen && (
                <div className={styles.menuOpen}>
                  <XMarkIcon height={25}/>
                </div>
              )}
            </div>
          </Fragment>
        )}
        {menuRoutes.includes(path) || location.pathname === routes.Survey.links.intro ? (
          <>
            <div
              className={styles.menuHeader}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {!isMenuOpen && (
                <div className={styles.menuClose}>
                  <Bars2Icon/>
                  <Text type="span" children="Menu"/>
                </div>
              )}
              {isMenuOpen && (
                <div className={styles.menuOpen}>
                  <XMarkIcon/>
                </div>
              )}
            </div>
            <ul className={styles.navbar}>
              <li
                className={
                  path === "chi-siamo" ? `${styles.pageSelected}` : undefined
                }
                onClick={() => window.location.href = `${appSiteUrl}/chi-siamo`}
              >
                Chi siamo
              </li>
              <li
                className={
                  path === routes.OurApproach.path.split("/")[1]
                    ? `${styles.pageSelected}`
                    : undefined
                }
                onClick={() => window.location.href = `${appSiteUrl}/il-nostro-approccio`}
              >
                Il nostro approccio
              </li>
              <li
                className={
                  path === routes.ContactUs.path.split("/")[1]
                    ? `${styles.pageSelected}`
                    : undefined
                }
                onClick={() => window.location.href = `${appSiteUrl}/blog/`}
              >
                Myworld
              </li>
              <li
                className={
                  path === routes.ContactUs.path.split("/")[1]
                    ? `${styles.pageSelected}`
                    : undefined
                }
                onClick={() => window.location.href = `${appSiteUrl}/contatti`}
              >
                Contatti
              </li>
            </ul>
              <div className={`${styles.btnWrapper} ${styles.homepageLogin}`}>
                <Button
                  variant="secondary"
                  className={`${styles.btnToSurvey} ${
                    path === routes.Login.path.split('/')[1]
                      ? styles.pageSelected
                      : undefined
                  }`}
                  onClick={async () => {
                    if (accessToken) {
                      const result = await getCurrentUserInfoResponse.refetch();
                      if (result.status === 'error') {
                        console.error(result.error);
                        return;
                      }

                      if (result.data?.user?.role === 'therapist') {
                        return navigate(routes.Chat.path);
                      }

                      if (!result.data?.user?.therapistId) {
                        navigate(routes.ChooseTherapist.path, {
                          ...(result.data?.user?.emailVerified
                            ? {}
                            : {
                              state: result.data?.user?.email,
                            }),
                        });
                      } else {
                        navigate(routes.Chat.path);
                      }
                    } else {
                      navigate(routes.Login.path);
                    }
                  }}
                >
                  Accedi
                </Button>
                {!(location.pathname === routes.Survey.links.intro) && (
                  <Button
                    className={styles.btnToSurvey}
                    rightIcon={<ChevronRightIcon/>}
                    onClick={() => navigate(routes.Survey.links.intro)}
                  >
                    Vai al questionario
                  </Button>
                )}
              </div>
          </>
        ) : location.pathname === routes.Survey.links.intro ? (
          <div className={styles.toLogin}>
            Hai già un profilo?{" "}
            <span
              onClick={async () => {
                if (accessToken) {
                  const result = await getCurrentUserInfoResponse.refetch();
                  if (result.status === "error") {
                    console.error(result.error);
                    return;
                  }

                  if (result.data?.user?.role === "therapist") {
                    return navigate(routes.Chat.path);
                  }

                  if (!result.data?.user?.therapistId) {
                    navigate(routes.ChooseTherapist.path, {
                      ...(result.data?.user?.emailVerified
                        ? {}
                        : {
                          state: result.data?.user?.email,
                        }),
                    });
                  } else {
                    navigate(routes.Chat.path);
                  }
                } else {
                  navigate(routes.Login.path);
                }
              }}
            >
              Accedi
            </span>
          </div>
        ) : null}
        {
          ((location.pathname === routes.PatientBookingFirstAppointment.path) && getCurrentUserInfoResponse?.data?.user?.id) &&
          <div onClick={() => setIsAppointmentMenuOpen(!isAppointmentMenuOpen)} className={styles.appointmentMenu}>
            <Avatar className={styles.profilePicture}
                    avatarImg={`${envs.REACT_APP_S3_ENDPOINT}/pic-${getCurrentUserInfoResponse.data?.user.id}?t=${Date.now()}`}
            />
            <div>{getCurrentUserInfoResponse?.data?.user?.given_name}</div>
            {!isAppointmentMenuOpen ? <ChevronDownIcon height={25}/> : <ChevronUpIcon height={25}/>}
            {isAppointmentMenuOpen &&
              <div className={styles.appointmentMenuOpen}>
                <span onClick={async () => {
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("storedChat");
                  localStorage.removeItem("greyBlock");
                  localStorage.removeItem("userId");
                  navigate(routes.Login.path);
                  queryClient.removeQueries();
                }}>Esci
                    </span>
              </div>
            }
          </div>
        }
      </div>
      {isMenuOpen && (
        <div className={styles.menu}>
          <ul className={styles.menuLinks}>
            {location.pathname !== routes.ChooseTherapist.path ? (
              <Fragment>
                <li
                  className={
                    path === "chi-siamo" ? `${styles.pageSelected}` : undefined
                  }
                  onClick={() => window.location.href = `${appSiteUrl}/chi-siamo`}
                >
                  Chi siamo
                </li>
                <li
                  className={
                    path === routes.OurApproach.path.split("/")[1]
                      ? `${styles.pageSelected}`
                      : undefined
                  }
                  onClick={() => window.location.href = `${appSiteUrl}/il-nostro-approccio`}
                >
                  Il nostro approccio
                </li>
                <li
                  className={
                    path === routes.ContactUs.path.split("/")[1]
                      ? `${styles.pageSelected}`
                      : undefined
                  }
                  onClick={() => window.location.href = `${appSiteUrl}/blog/`}
                >
                  Myworld
                </li>
                <li
                  className={
                    path === routes.ContactUs.path.split("/")[1]
                      ? `${styles.pageSelected}`
                      : undefined
                  }
                  onClick={() => window.location.href = `${appSiteUrl}/contatti`}
                >
                  Contatti
                </li>
                <Button
                  variant="secondary"
                  onClick={async () => {
                    if (accessToken) {
                      const result = await getCurrentUserInfoResponse.refetch();
                      if (result.status === "error") {
                        console.error(result.error);
                        return;
                      }

                      if (result.data?.user?.role === "therapist") {
                        return navigate(routes.Chat.path);
                      }

                      if (!result.data?.user?.therapistId) {
                        navigate(routes.ChooseTherapist.path, {
                          ...(result.data?.user?.emailVerified
                            ? {}
                            : {
                              state: result.data?.user?.email,
                            }),
                        });
                      } else {
                        navigate(routes.Chat.path);
                      }
                    } else {
                      navigate(routes.Login.path);
                    }
                    setIsMenuOpen(false);
                  }}
                >
                  Accedi
                </Button>
                {!(location.pathname === routes.Survey.links.intro) && (
                  <Button
                    onClick={() => {
                      navigate(routes.Survey.links.intro);
                      setIsMenuOpen(false);
                    }}
                  >
                    Vai al questionario
                  </Button>
                )}
              </Fragment>
            ) : (
              <li
                onClick={() => {
                  // document.cookie = `accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                  // document.cookie = `refreshToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("greyBlock");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("storedChat");
                  localStorage.removeItem("userId");
                  navigate(routes.Login.path);
                  queryClient.removeQueries();
                }}
              >
                Esci
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
};