import { HeartIcon, CalendarIcon, CurrencyEuroIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import Input from "../../components/atoms/Input";
import greenShape from "../../theme/images/registerGreenShape.png";
import purpleShape from "../../theme/images/registerPurpleShape.png";
import { trpc } from "../../utils/trpc";
import styles from "./PatientRegister.module.scss";
import routes from "routes";
import { getToken } from "utils/getToken";
import { useFormik } from "formik";
import * as yup from "yup";
import { Alert } from "antd";
import { analyticsService } from "../../utils/analyticsService";
import supporto from "../../theme/images/supporto.webp";
import { envs } from "../../utils/envs";
import { raiseException } from "../../utils/raiseException";
import trimString from "../../utils/trimString";
import { useTherapistData } from '../../customHooks/therapistDataContext';

const appSiteUrl = envs.REACT_APP_SITE_URL;

export const PatientRegister = ({className}: any) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location;
	const {removeTherapistData} = useTherapistData();
	const gender = state?.gender ? state.gender : "other";
	const [registerLoading, setRegisterLoading] = useState(false);
	const [registerError, setRegisterError] = useState<string | null>();
	const [passwordTypeToggle, setPasswordTypeToggle] = useState(true);
	const [answers, setAnswers] = useState<any>([]);
	const [acceptConditions, setAcceptConditions] = useState<boolean>(false);
	const [newsLetter, setNewsLetter] = useState(false);

	const formik = useFormik({
		initialValues: {
			givenName: '',
			email: '',
			confirmEmail: '',
			emailPartner: '',
			emailParent: '',
			password: '',
			acceptConditions: false,
		},
		validationSchema: yup.object().shape({
			givenName: yup
				.string()
				.min(3, 'Il nome deve contenere almeno 3 caratteri')
				.max(50, 'Il nome non può superare i 50 caratteri')
				.required('Il nome è un campo obbligatorio'),
			email: yup
				.string()
				.trim()
				.matches(
					/^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
					"L'email inserita ha un formato non valido"
				)
				.required("L'email è un campo obbligatorio"),
			emailPartner: yup
				.string()
				.trim()
				.matches(
					/^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
					"L'email inserita ha un formato non valido"
				),
			emailParent: yup
				.string()
				.trim()
				.matches(
					/^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
					"L'email inserita ha un formato non valido"
				),
			password: yup
				.string()
				.matches(
					/^(?=.*[a-z])(?=.*\d)[^\s]{8,}$/,
					"La password non rispetta i criteri di sicurezza"
				)
				.required("La password è un campo obbligatorio"),
			acceptConditions: yup
				.boolean()
				.isTrue("Devi accettare le condizioni per poterti registrare")
				.required(),
		}),
		onSubmit: (values) => {
			const {givenName}: any = values;
			const spaceIndex = givenName.indexOf(' ');
			if (spaceIndex === -1) {
				register({
					givenName,
					familyName: '',
					email: values.email,
					emailPartner: values.emailPartner,
					emailParent: values.emailParent,
					password: values.password,
				});
			} else {
				const firstName = givenName.substring(0, spaceIndex);
				const lastName = givenName.substring(spaceIndex + 1);
				register({
					givenName: firstName,
					familyName: lastName,
					email: values.email,
					emailPartner: values.emailPartner,
					emailParent: values.emailParent,
					password: values.password,
				});
			}
		}
	});

	const registerService = trpc.authService.auth.register.useMutation();

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery(undefined, {
      enabled: !!getToken("accessToken"),
  });

  useEffect(() => {
		analyticsService({ 'event': 'survey_submitted' });
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/registrazione', 'pageTitle': 'Patient Register'});
  }, []);

  const register = ({
    givenName,
    familyName,
    emailPartner,
    emailParent,
    email,
    password,
  }: {
    givenName: string;
    familyName: string;
    email: string;
    emailPartner: string;
    emailParent: string;
    password: string;
  }) => {
    setRegisterError(null);
    setRegisterLoading(true);
    registerService
      .mutateAsync({
        survey: answers,
        given_name: trimString(givenName),
        family_name: trimString(familyName),
        gender: trimString(gender),
        email: trimString(email),
        partnerEmail: trimString(emailPartner) || undefined,
        parentEmail: trimString(emailParent) || undefined,
        password: trimString(password),
        newsletter: newsLetter,
      })
      .then((data) => {
        localStorage.removeItem("storedChat");
				removeTherapistData();
        const { tokens } = data;
        const { accessToken, refreshToken } = tokens;
        // document.cookie = `accessToken=${accessToken}; Path=/;`;
        // document.cookie = `refreshToken=${refreshToken}; Path=/;`;
        localStorage.setItem("accessToken", accessToken!);
        localStorage.setItem("refreshToken", refreshToken!);
        analyticsService({ 'event': 'user_registered' });
        navigate(routes.ChooseTherapist.path, {
					state: { email, campaignId: state?.campaignId }});
      })
      .catch((e) => {
				raiseException(e);
        setRegisterLoading(false);
        if (e?.shape?.errorCode === "EmailAlreadyExists") {
          if (e?.shape?.message === "partner") {
            formik.setErrors({ emailPartner: "L'email inserita è già in uso" });
          } else if (e?.shape?.message === "parent") {
            formik.setErrors({ emailParent: "L'email inserita è già in uso" });
          } else {
            formik.setErrors({ email: "L'email inserita è già in uso" });
          }
        } else {
          setRegisterError(
            "Si è verificato un errore inaspettato. Verifica di essere connesso a internet e riprova."
          );
        }
      });
  };

	useEffect(() => {
		if (!state) {
			navigate(routes.Survey.links.intro);
		} else {
			setAnswers(state?.answers);
		}
	}, [state]);

	return (
		<div className={`${styles.register} ${className}`}>
			<img src={greenShape} alt="green shape" className={styles.greenShape}/>
			<img
				src={purpleShape}
				alt="purple shape"
				className={styles.purpleShape}
			/>
			<div className={styles.registerCard}>
				<div className={styles.instruction}>
					<div className={styles.topPart}>
						{state?.answers.id === "762bbb63-a571-44ab-a135-607e4ae07968" ?
							<span>È il momento di incontrare il vostro terapeuta</span>
							:
							state?.answers.id === "a3b8aa4f-aebb-477e-a8c6-f07739b9e108" ?
								<span>È tempo di conoscere il terapeuta</span>
								: state?.answers.id === "df5fbfdd-db8f-42d7-bbbd-23533aa7d476" ?
									<span>Registrati e conosci il tuo psichiatra</span>

									: <span>È il momento di conoscere il tuo terapeuta</span>
						}
					</div>
					<div className={styles.bottomPart}>
						{state?.answers.id === "df5fbfdd-db8f-42d7-bbbd-23533aa7d476" ?
							<>
								<div className={styles.bottomRow}>
									<div className={styles.iconWrapper}>
										<CalendarIcon/>
									</div>
									<div className={styles.text}>
										Potrai prenotare una visita specialistica online e ricevere un supporto professionale e su misura per le tue esigenze.
									</div>
								</div>
								<div className={styles.bottomRow}>
									<div className={styles.iconWrapper}>
										<CurrencyEuroIcon/>
									</div>
									<div className={styles.text}>
										La visita ha un costo di <span style={{fontWeight: "bold"}}>150</span> euro, che potrai detrarre dalle tue tasse
									</div>
								</div>
							</>
							:
							<div className={styles.bottomRow}>
								<div className={styles.iconWrapper}>
									<HeartIcon/>
								</div>
								<div className={styles.text}>
									<span style={{fontWeight: "bold"}}>Registrati</span> ora, il primo colloquio è gratuito{" "}
								</div>
							</div>
						}
						{/*<div className={styles.bottomRow}>*/}
						{/*	<div className={styles.iconWrapper}>*/}
						{/*		<UserGroupIcon/>*/}
						{/*	</div>*/}
						{/*	<div className={styles.text}>*/}
						{/*		<span style={{fontWeight: "bold"}}>Scegli il terapeuta</span>{" "}*/}
						{/*		tra i tre selezionati da noi*/}
						{/*	</div>*/}
						{/*</div>*/}
						{/*<div className={styles.bottomRow}>*/}
						{/*	<div className={styles.iconWrapper}>*/}
						{/*		<ClockIcon/>*/}
						{/*	</div>*/}
						{/*	<div className={styles.text}>*/}
						{/*		Prenota{" "}*/}
						{/*		<span style={{fontWeight: "bold"}}>l’incontro gratuito</span>*/}
						{/*	</div>*/}
						{/*</div>*/}
						<img className={styles.questionCardImage} src={supporto}
								 alt="percorsoPersonalizzato"/>
					</div>
				</div>
				<form onSubmit={formik.handleSubmit} className={styles.form}>
					<div className={styles.inputsWrapper}>
						<div className={styles.textInputWrapper}>
							<Input
								placeholder="Nome reale o di fantasia"
								label="Nome"
								name="givenName"
								value={formik.values.givenName}
								onChange={formik.handleChange}
								error={
									(formik.errors.givenName &&
										formik.touched.givenName &&
										formik.errors.givenName) as string
								}
							/>
							{/*<Input*/}
							{/*	placeholder="Cognome"*/}
							{/*	label="Cognome"*/}
							{/*	name="familyName"*/}
							{/*	value={formik.values.familyName}*/}
							{/*	onChange={formik.handleChange}*/}
							{/*	error={*/}
							{/*		(formik.errors.familyName &&*/}
							{/*			formik.touched.familyName &&*/}
							{/*			formik.errors.familyName) as string*/}
							{/*	}*/}
							{/*/>*/}
							<Input
								placeholder="Email"
								label="Email"
								type="email"
								name="email"
								value={formik.values.email}
								onChange={formik.handleChange}
								error={
									(formik.errors.email &&
										formik.touched.email &&
										formik.errors.email) as string
								}
							/>
							{/*<Input*/}
							{/*	placeholder="Conferma Email"*/}
							{/*	label="Conferma Email"*/}
							{/*	type="email"*/}
							{/*	name="confirmEmail"*/}
							{/*	onChange={formik.handleChange}*/}
							{/*	value={formik.values.confirmEmail}*/}
							{/*	error={*/}
							{/*		(formik.errors.confirmEmail &&*/}
							{/*			formik.touched.confirmEmail &&*/}
							{/*			formik.errors.confirmEmail) as string*/}
							{/*	}*/}
							{/*/>*/}
							{state?.answers.id === "762bbb63-a571-44ab-a135-607e4ae07968" && (
								<Input
									placeholder="Email partner"
									label="Email partner"
									type="email"
									name="emailPartner"
									value={formik.values.emailPartner}
									onChange={formik.handleChange}
									error={
										(formik.errors.emailPartner &&
											formik.touched.emailPartner &&
											formik.errors.emailPartner) as string
									}
								/>
							)}
							{state?.answers.id === "a3b8aa4f-aebb-477e-a8c6-f07739b9e108" && (
								<Input
									placeholder="Email genitore"
									label="Email genitore"
									type="email"
									name="emailParent"
									value={formik.values.emailParent}
									onChange={formik.handleChange}
									error={
										(formik.errors.emailParent &&
											formik.touched.emailParent &&
											formik.errors.emailParent) as string
									}
								/>
							)}
							<Input
								className={styles.passwordInput}
								type={passwordTypeToggle ? "password" : "text"}
								name="password"
								label="Password"
								value={formik.values.password}
								onChange={formik.handleChange}
								error={
									(formik.errors.password &&
										formik.touched.password &&
										formik.errors.password) as string
								}
								placeholder="Password"
								rightIcon={
									<p
										className={styles.showPw}
										onClick={() => {
											setPasswordTypeToggle(!passwordTypeToggle);
										}}
									>
										{passwordTypeToggle ? "Mostra" : "Nascondi"}
									</p>
								}
							/>
							<div className={styles.pwInfo}>
								La password deve contenere almeno 8 caratteri di cui:{" "}
								<p>un carattere maiuscolo e un carattere numerico</p>
							</div>
						</div>
						<div className={styles.checkboxWrapper}>
							<Input
								checked={acceptConditions}
								className={styles.checkbox}
								onChange={(e) => {
									formik.setValues({
										...formik.values,
										acceptConditions: e.target.checked,
									});
									setAcceptConditions(e.target.checked);
								}}
								type="checkbox"
								checkboxId="termini e le condizioni"
								checkboxLabel={
									<div>
										Ho letto e accetto i{" "}
										<span
											onClick={() => window.open(appSiteUrl + routes.TermsAndCondition.path)}
											style={{
												color: "#2C2C4B",
												textDecoration: "underline",
												cursor: "pointer",
											}}
										>
                      termini e le condizioni<span>*</span>
                    </span>
									</div>
								}
							/>
							{formik.errors.acceptConditions &&
								formik.touched.acceptConditions &&
								formik.errors.acceptConditions && (
									<Alert
										closeIcon={null}
										className={styles.registerError}
										type="error"
										message={formik.errors.acceptConditions}
										closable
									/>
								)}
							{/* <Input
                type="checkbox"
                checkboxId="informativa privacy"
                checkboxLabel={
                  <div>
                    Ho letto e compreso{" "}
                    <span style={{ color: "#2C2C4B" }}>
                      l’informativa privacy
                    </span>
                  </div>
                }
              /> */}
							<Input
								onChange={(e) => {
									setNewsLetter(e.target.checked);
								}}
								className={styles.checkbox}
								type="checkbox"
								checkboxId="newsletter"
								checkboxLabel={
									<div>
										Voglio iscrivermi alla{" "}
										<span style={{color: "#2C2C4B"}}>newsletter</span> di
										Mymentis
									</div>
								}
							/>
						</div>
						{registerError && (
							<Alert
								className={styles.registerError}
								type="error"
								message={registerError}
								closable
							/>
						)}
						<Button
							className={styles.btn}
							disabled={registerLoading}
							type="submit"
						>
							Registrati
						</Button>
						<div className={styles.privacyDisclaimer}>
							Cliccando su registrati dichiari di aver letto e compreso{" "}
							<span
								style={{cursor: "pointer", textDecoration: "underline"}}
								onClick={() => window.open(`${answers?.id !== 'df5fbfdd-db8f-42d7-bbbd-23533aa7d476' ? 'https://mymentis.it/privacy-paziente/' : 'https://mymentis.it/privacy-policy-paziente-psc/'}`, '_blank')}
							>
                le informative privacy
              </span>{" "}
							dei contitolari e della piattaforma
						</div>
					</div>
					<div className={styles.login}>
						<div className={styles.spacer}></div>
						<div>
							Hai già un profilo?
							<span
								onClick={async () => {
									const accessToken = getToken("accessToken");
									const result = await getCurrentUserInfoResponse.refetch();
									if (accessToken) {
										if (result.data?.user?.role === "therapist") {
											return navigate(routes.Chat.path);
										}

										if (!result.data?.user?.therapistId) {
											navigate(routes.ChooseTherapist.path, {
												...(result.data?.user?.emailVerified
													? {}
													: {
														state: result.data?.user?.email,
													}),
											});
										} else {
											navigate(routes.Chat.path);
										}
									} else {
										navigate(routes.Login.path);
									}
								}}
								style={{
									color: "$primary-1_dark",
									fontWeight: 600,
									cursor: "pointer",
								}}
							>
                Accedi
              </span>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};
