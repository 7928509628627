import React, { createContext, useContext, useEffect, useState } from 'react';
import { LocalStorageService } from '../utils/localStorageService';

type TherapistDataContextType = {
  therapistData: any;
  setTherapistData: (data: any) => void;
  removeTherapistData: () => void;
  clearLocalStorage: () => void;
};

const TherapistDataContext = createContext<TherapistDataContextType | undefined>(undefined);

export const TherapistDataProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [therapistData, setTherapistData] = useState<any>(null);

  useEffect(() => {
    const data = LocalStorageService.getItem<any>('therapistData');
    setTherapistData(data);
  }, []);

  const updateTherapistData = (data: any) => {
    LocalStorageService.setItem('therapistData', data);
    setTherapistData(data);
  };

  const removeTherapistData = () => {
    LocalStorageService.removeItem('therapistData');
    setTherapistData(null);
  };

  const clearLocalStorage = () => {
    LocalStorageService.clear();
    setTherapistData(null);
  };

  return (
    <TherapistDataContext.Provider
      value={{
        therapistData,
        setTherapistData: updateTherapistData,
        removeTherapistData,
        clearLocalStorage,
      }}
    >
      {children}
    </TherapistDataContext.Provider>
  );
};

export const useTherapistData = () => {
  const context = useContext(TherapistDataContext);
  if (!context) {
    throw new Error('useTherapistData must be used within a TherapistDataProvider');
  }
  return context;
};