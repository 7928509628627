import Tabs from "../../atoms/Tabs";
import styles from "./DocSheet.module.scss";
import DocCard from "../../molecules/DocCard";
import DocInfo from "../../molecules/DocInfo";

interface Props {
  className?: string;
  doc: any;
  patientData: any;
}

export const DocSheet = ({ className = "", doc, patientData }: Props) => {
  return (
    <div className={`${styles.docSheet} ${className}`}>
      <DocCard doc={doc} className={styles.docCard} />
      <Tabs
        className={styles.tabs}
        labelClassName={styles.tabsWrapper}
        activeLabelClassName={styles.activeLabel}
        activeClassName="docSheet"
        items={[
          {
            key: 1,
            label: "Informazioni",
            active: true,
            content: <DocInfo patient={patientData} myTherapist={doc}/>,
          },
        ]}
      />
    </div>
  );
};
