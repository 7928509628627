import Button from "components/atoms/Button";
import styles from "./TherapistPreferences.module.scss";
import Input from "components/atoms/Input";
import { trpc } from "utils/trpc";
import { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { getCPS } from "utils/general";
import { useAppDispatch } from "customHooks/reduxHooks";
import { setInfo } from "store/slices/infoSlice";
import Modal from "../Modal";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import GenericForm from "components/molecules/GenericForm";
import FormHeader from "components/atoms/FormHeader";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import { envs } from "../../../utils/envs";
import { queryClient } from "../../../index";
import TherapistUploadFirma from '../TherapistUploadFirma'

interface Props {
  userInfo: any;
}

export const TherapistPreferences = ({userInfo}: Props) => {
  const [stopNewMatches, setStopNewMatches] = useState(false);
  const [stopNewMatchesConfirmModal, setStopNewMatchesConfirmModal] =
    useState(false);
  const [timeSlots, setTimeSlots] = useState<any[]>([]);
  const [daySlots, setDaySlots] = useState<any[]>([]);
  const [specializations, setSpecializations] = useState<any[]>([]);
  const [guidelineName, setGuidelineName] = useState<string>('');
  const [signatureName, setSignatureName] = useState<string>('');
  const [maxWeeklyHoursAvailability, setMaxWeeklyHoursAvailability] =
    useState(0);
  const [minWeeklyHoursAvailability, setMinWeeklyHoursAvailability] =
    useState(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/dashboard/profilo/preferenze',
      'pageTitle': 'Therapist Preferences'
    });
  }, []);

  const isPsychiatry = userInfo?.therapies?.includes('psychiatry')

  const updatePreferences =
    trpc.mainService.therapist.updatePreferences.useMutation();

  const { data: therapistPreferences, refetch: refetchPreferences } =
    trpc.mainService.therapist.getPreferences.useQuery();

  const getAllSpecializationsResponse =
    trpc.mainService.therapist.getAllSpecializations.useQuery();

  const deleteSignature = trpc.mainService.medicalDocuments.deleteSignature.useMutation();

  const onUpdatePreferences = () => {
    updatePreferences
      .mutateAsync({
        stopNewMatches,
        timeSlots,
        daySlots,
        specializations: isPsychiatry ? specializations.map(el => `p${el}`) : specializations,
        maxWeeklyHoursAvailability,
        minWeeklyHoursAvailability,
      })
      .then((data) => {
        refetchPreferences();
        setDaySlots(data.preferences.daySlots!);
        setSpecializations(data.preferences.specializations!);
        setTimeSlots(data.preferences.timeSlots!);
        setMaxWeeklyHoursAvailability(
          data.preferences.maxWeeklyHoursAvailability!
        );
        setMinWeeklyHoursAvailability(
          data.preferences.minWeeklyHoursAvailability!
        );
        setStopNewMatches(data.preferences.stopNewMatches!);
        dispatch(
          setInfo({ text: "Dati aggiornati con successo", variant: "confirm" })
        );
      })
      .catch((e) => {
        dispatch(
          setInfo({
            variant: "error",
            text: "Si è verificato un errore durante il salvataggio delle informazioni",
          })
        );
        raiseException(e);
        console.error("ERROR", e?.shape?.message);
      });
  };

  const updateStopMatch = () => {
    updatePreferences
      .mutateAsync({
        stopNewMatches: !stopNewMatches,
      })
      .then((data) => {
        refetchPreferences();
        setStopNewMatches(data.preferences.stopNewMatches!);
        setStopNewMatchesConfirmModal(false);
      })
      .catch((e) => {
        setStopNewMatchesConfirmModal(false);
        dispatch(
          setInfo({
            variant: "error",
            text: "Si è verificato un errore durante il salvataggio delle informazioni",
          })
        );
        raiseException(e);
        console.error("ERROR", e?.shape?.message);
      });
  };

  useEffect(() => {
    if (therapistPreferences && userInfo) {
      setStopNewMatches(therapistPreferences.preferences?.stopNewMatches!);
      setMinWeeklyHoursAvailability(
        therapistPreferences.preferences?.minWeeklyHoursAvailability!
      );
      setMaxWeeklyHoursAvailability(
        therapistPreferences.preferences?.maxWeeklyHoursAvailability!
      );
      setDaySlots(therapistPreferences.preferences?.daySlots!);
      setSpecializations(therapistPreferences.preferences?.specializations!);
      setTimeSlots(therapistPreferences.preferences?.timeSlots!);
      setGuidelineName(therapistPreferences.preferences?.['guideline_name']);
      setSignatureName(therapistPreferences.preferences?.['signature']);
    }
  }, [therapistPreferences, userInfo]);

  const deleteSign = async () => {
    await deleteSignature.mutateAsync({signatureName: signatureName}).then(
      () => {
        queryClient.removeQueries();
        setSignatureName('');
        dispatch(
          setInfo({
            text: "Il file è stato eliminato con successo",
            variant: "confirm",
          })
        );
      }
    ).catch((e) => {
      raiseException(e);
      console.log('Error: ', e)
      dispatch(
        setInfo({
          variant: "error",
          text: 'Si è verificato un errore durante eliminazione di un file',
          title: "Error",
        })
      );
    });
  }

  return (
    <div className={styles.therapistPreferences}>
      {updatePreferences.isLoading ? (
        <Modal className="loadingModal">
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
            }
          />
        </Modal>
        ) :
        <>
          <h1 className={styles.title}>Gestione preferenze</h1>
          {!isPsychiatry &&
            <div className={styles.guidelinesWrapper}>
            <span>Linee guida:</span>
            <a href={`${envs.REACT_APP_S3_ENDPOINT}/${guidelineName}`} target="_blank" rel="noreferrer">
              <Button variant="primary">
                Scarica il PDF
              </Button>
            </a>
          </div>
          }
          {isPsychiatry &&
            <div className={styles.firmaWrapper}>
              <div className={styles.name}>Firma:</div>
              <TherapistUploadFirma userInfo={userInfo}
                                    setIsModalOpen={setIsModalOpen}
                                    signatureName={signatureName}
              />
            </div>
          }
          <div className={styles.infoBox}>
            <div className={styles.textPart}>
              <div className={styles.textTitle}>Interrompi nuovi "match"</div>
              <div className={styles.description}>
                Nel caso non potessi più gestire nuovi match, potrai interrompere
                tramite questo pulsante. Interrompendo, non ti verranno più
                assegnati nuovi pazienti. Potrai riprendere quando vuoi.
              </div>
            </div>
            <Button
              className={stopNewMatches ? styles.resumeBtn : styles.stopBtn}
              variant="secondary"
              onClick={() => setStopNewMatchesConfirmModal(true)}
            >
              {stopNewMatches ? 'Riprendi' : 'Interrompi'}
            </Button>
          </div>
          {userInfo &&
            <div className={styles.preferenceCard}>
              <div className={styles.preferenceTitle}>Competenze cliniche</div>
              <div className={styles.preferenceDescription}>
                Questa è la lista delle competenze cliniche che hai indicato al
                momento della registrazione. Se necessario puoi aggiornarla, ma cerca
                di farlo il meno possibile al fine di rendere più efficace l’algoritmo
                di matching
              </div>
              <div className={styles.cpMultiselect}>
                <Multiselect
                  onSelect={(_, selectedSlot) => {
                    setSpecializations([...specializations, selectedSlot.value]);
                  }}
                  onRemove={(_, deselectedSlot) => {
                    setSpecializations(
                      specializations.filter((s) => s !== deselectedSlot.value)
                    );
                  }}
                  displayValue="name"
                  showCheckbox
                  placeholder="Competenze cliniche"
                  selectedValues={therapistPreferences?.preferences?.specializations?.map(
                    (specialization) => ({
                      name: getCPS(isPsychiatry ? [`p${specialization}`] : [specialization]).join(", "),
                      value: specialization,
                    })
                  )}
                  options={getAllSpecializationsResponse.data?.specializations
                    .filter((specialization) => (isPsychiatry ? specialization.id.startsWith('p') : !specialization.id.startsWith('p'))).map(
                      (specialization) => ({
                        name: getCPS([specialization.id]).join(", "),
                        value: isPsychiatry ? specialization.id.slice(1) : specialization.id,
                      })
                    )}
                />
                <XMarkIcon className={styles.closeIcon}/>
              </div>
            </div>
          }
          <div className={styles.preferenceCard}>
            <div className={styles.preferenceTitle}>
              Disponibilità per fasce orarie
            </div>
            <div className={styles.preferenceDescription}>
              Queste sono le fasce orarie di disponibilità che hai indicato in fase
              di registrazione. È importante tenerle aggiornate nel caso di
              variazioni. Potrai modificarle ogni volta che vuoi, ma cerca di
              variarle il meno possibile al fine di rendere più efficace l'algoritmo
              di matching.
            </div>

            <Multiselect
              onSelect={(_, selectedSlot) => {
                setTimeSlots([...timeSlots, selectedSlot.value]);
              }}
              onRemove={(_, deselectedSlot) => {
                setTimeSlots(timeSlots.filter((s) => s !== deselectedSlot.value));
              }}
              displayValue="name"
              showCheckbox
              placeholder=""
              showArrow
              customArrow={<ChevronDownIcon/>}
              selectedValues={timeSlots.map((d) => {
                if (d === "morning") {
                  return {name: "Mattina (8:00 - 13:00)", value: "morning"};
                }
                if (d === "afternoon") {
                  return {name: "Pomeriggio (13:00 - 18:00)", value: "afternoon"};
                }
                if (d === "late_afternoon") {
                  return {name: "Tardo pomeriggio (18:00 - 20:00)", value: "late_afternoon"};
                }
                if (d === "evening") {
                  return {name: "Sera (20:00 - 22:00)", value: "evening"};
                }
              })}
              options={[
                {name: "Mattina (8:00 - 13:00)", value: "morning"},
                {name: "Pomeriggio (13:00 - 18:00)", value: "afternoon"},
                {name: "Tardo pomeriggio (18:00 - 20:00)", value: "late_afternoon"},
                {name: "Sera (20:00 - 22:00)", value: "evening"}
              ]}
            />
          </div>
          <div className={styles.preferenceCard}>
            <div className={styles.preferenceTitle}>Disponibilità giorni</div>
            <div className={styles.preferenceDescription}>
              Questi sono i giorni in cui preferisci incontrare i pazienti. È
              importante tenere aggiornate le tue disponibilità in caso di
              variazioni. Potrai modificarle ogni volta che vuoi, ma cerca di farlo
              il meno possibile al fine di rendere più efficace l’algoritmo di
              matching
            </div>
            <Multiselect
              onSelect={(_, selectedSlot) => {
                setDaySlots([...daySlots, selectedSlot.value]);
              }}
              onRemove={(_, deselectedSlot) => {
                setDaySlots(daySlots.filter((s) => s !== deselectedSlot.value));
              }}
              displayValue="name"
              showCheckbox
              placeholder=""
              showArrow
              customArrow={<ChevronDownIcon/>}
              selectedValues={daySlots.map((d) => {
                if (d === "week") {
                  return {name: "Lun - Ven", value: "week"};
                }
                if (d === "saturday") {
                  return {name: "Sabato", value: "saturday"};
                }
                if (d === "sunday") {
                  return {name: "Domenica", value: "sunday"};
                }
              })}
              options={[
                {name: "Lun - Ven", value: "week"},
                {name: "Sabato", value: "saturday"},
                {name: "Domenica", value: "sunday"},
              ]}
            />
          </div>
          <div className={styles.preferenceCard}>
            <div className={styles.preferenceTitle}>
              Ore settimanali disponibili (Min/Max)
            </div>
            <div className={styles.preferenceDescription}>
              Queste sono le ore minime e massime che potrai svolgere nelle visite settimanali. Potrai aggiornare questo
              dato ogni volta che vuoi.
            </div>
            <Input
              select
              onChange={(e) => {
                const value = JSON.parse(e.target.value);
                setMinWeeklyHoursAvailability(value.min);
                setMaxWeeklyHoursAvailability(value.max);
              }}
              value={`{ "min": ${minWeeklyHoursAvailability}, "max": ${maxWeeklyHoursAvailability} }`}
              options={[
                {name: "6 ore - 12 ore", value: '{ "min": 6, "max": 12 }'},
                {name: "12 ore - 18 ore", value: '{ "min": 12, "max": 18 }'},
                {name: "18 ore - 24 ore", value: '{ "min": 18, "max": 24 }'},
                {name: "24 ore - 30 ore", value: '{ "min": 24, "max": 30 }'},
                {name: "30 ore - 36 ore", value: '{ "min": 30, "max": 36 }'},
              ]}
            />
          </div>
          <div className={styles.btnWrapper}>
            <Button
              variant="secondary"
              onClick={() => {
                if (therapistPreferences) {
                  setStopNewMatches(
                    therapistPreferences.preferences?.stopNewMatches!
                  );
                  setMinWeeklyHoursAvailability(
                    therapistPreferences.preferences?.minWeeklyHoursAvailability!
                  );
                  setMaxWeeklyHoursAvailability(
                    therapistPreferences.preferences?.maxWeeklyHoursAvailability!
                  );
                  setDaySlots(therapistPreferences.preferences?.daySlots!);
                  setSpecializations(
                    therapistPreferences.preferences?.specializations!
                  );
                  setTimeSlots(therapistPreferences.preferences?.timeSlots!);
                }
              }}
            >
              Annulla
            </Button>
            <Button
              disabled={updatePreferences.isLoading}
              onClick={() => onUpdatePreferences()}
            >
              Salva preferenze
            </Button>
          </div>
        </>
      }
      {stopNewMatchesConfirmModal && (
        <Modal close={() => {
          setStopNewMatchesConfirmModal(false);
        }}>
          <GenericForm
            isModal
            className={styles.stopNewMatchesModal}
            title={
              <FormHeader
                closeModal={() => setStopNewMatchesConfirmModal(false)}
                title={stopNewMatches ? "Riprendi match" : "Interrompi match"}
                closeButton
              />
            }
            description={
              stopNewMatches ? (
                <div>
                  <p>Sei sicuro di voler riabilitare i match?</p>
                  <p>Nuovi pazienti potranno essere associati a te</p>
                </div>
              ) : (
                <div>
                  <p>Sei sicuro di voler interrompere i match?</p>
                  <p>
                    Non potrai più ricevere pazienti fino a quando non lo
                    riattiverai
                  </p>
                </div>
              )
            }
            cta={[
              <Button
                onClick={() => setStopNewMatchesConfirmModal(false)}
                variant="secondary"
              >
                Annulla
              </Button>,
              <Button onClick={() => updateStopMatch()}>Prosegui</Button>,
            ]}
          />
        </Modal>
      )}
      {isModalOpen && (
        <Modal close={() => {
          setIsModalOpen(false);
        }}>
          <GenericForm
            isModal
            className={styles.stopNewMatchesModal}
            title={
              <FormHeader
                closeModal={() => setIsModalOpen(false)}
                title="Sei sicuro di voler eliminare la firma?"
                closeButton
              />
            }
            description=""
            cta={[
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="secondary"
              >
                Annulla
              </Button>,
              <Button onClick={deleteSign}>Prosegui</Button>,
            ]}
          />
        </Modal>
      )}
    </div>
  );
};
