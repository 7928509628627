import { FunctionComponent, useEffect, useRef, useState } from "react";
import Text from "../../../atoms/Text";
import Button from "../../../atoms/Button";
import Image from "../../../atoms/Image";
import styles from "./TextContainer.module.scss";
import { useNavigate } from "react-router-dom";

interface PropsButton {
  onClick?: any;
  leftIconButton?: any;
  rightIconButton?: any;
}

export interface PropsTextContainer extends PropsButton {
  intro?: boolean;
  label?: string;
  title?: string;
  titleType?: string;
  subtitle?: string;
  subtitleType?: string;
  paragraphs?: string[] | undefined;
  hasList?: boolean;
  titleListType?: string;
  listPoints?: [{ title?: string; points: string[] }];
  buttonTxt?: string;
  link?: string;
  iconLeftLink?: any;
  iconRigthLink?: any;
  handlerLabel?: (label: string) => void;
  textStains?: string[] | undefined;
}
export const TextContainer: FunctionComponent<PropsTextContainer> = (props) => {
  const stains = props.textStains;
  const surveyBtnRef = useRef(null);
  const [showSurveyFixedBtn, setShowSurveyFixedBtn] = useState(false);

  const onShowSurveyFixedBtn = () => {
    const surveyBtn = surveyBtnRef.current;
    if (surveyBtn) {
      const observer = new IntersectionObserver(
        (el) => {
          if (el[0].isIntersecting) {
            setShowSurveyFixedBtn(false);
          } else {
            setShowSurveyFixedBtn(true);
          }
          // if (el.length === messagesList.length) {
          //   const topMessage = el.filter((i) => i.isIntersecting === true);
          //   const topMessageLenght = topMessage.length - 1;
          //   el.map((i: any, index) => {
          //     const dateString = messagesList[index]?.when;
          //     const date = new Date(dateString);
          //     if (
          //       index !== el.length - 1 &&
          //       topMessage[topMessageLenght] &&
          //       topMessage[topMessageLenght].target !== undefined &&
          //       topMessage[topMessageLenght].target === i.target &&
          //       oldMessageDateIsVisible &&
          //       oldMessageDate !== dateToShow(date, "chat")
          //     ) {
          //       setOldMessageDate(dateToShow(date, "chat")!);
          //     }
          //   });
          // }
        },
        { threshold: [0] }
      );
      observer.observe(surveyBtn);
    }
  };

  useEffect(() => {
    onShowSurveyFixedBtn();
  }, [surveyBtnRef]);

  return (
    <div className={styles.textContainer}>
      {props.label && <Text type="label" children={props.label} />}
      {props.title &&
        props.title !== "L’eccellenza del benessere mentale, ora online" && (
          <Text type={props.titleType} children={props.title} />
        )}
      {props.title &&
        props.title === "L’eccellenza del benessere mentale, ora online" && (
          <Text type={props.titleType}>
            L'eccellenza del
            <br />
            benessere mentale,
            <br />
            ora online
          </Text>
        )}
      {props.subtitle && (
        <Text type={props.subtitleType} children={props.subtitle} />
      )}
      {props.paragraphs &&
        props.paragraphs.map((p, i) => {
          return <Text key={i} type="p" children={p} />;
        })}
      {props.listPoints &&
        props.listPoints.map(
          (list: { title?: string; points: string[] }, index: number) => {
            if (list.title !== "links") {
              return (
                <div key={index} className={styles.listContainer}>
                  {list.title && (
                    <Text type={props.titleListType} children={list.title} />
                  )}
                  <ul>
                    {list.points.map((point: string, i: number) => {
                      return (
                        <Text
                          onClick={() =>
                            props.handlerLabel && props.handlerLabel(point)
                          }
                          key={i}
                          type="li"
                          children={point}
                        />
                      );
                    })}
                  </ul>
                </div>
              );
            } else {
              return (
                <div key={index} className={styles.linksContainer}>
                  <ul>
                    {list.points.map((point: string, i: number) => {
                      if (i % 2 === 0) {
                        return (
                          <Text
                            onClick={() => {
                              window.open(list.points[i + 1], "_blank");
                            }}
                            key={i}
                            type="link"
                            children={point}
                          />
                        );
                      }
                    })}
                  </ul>
                </div>
              );
            }
          }
        )}
      {props.buttonTxt && (
        <Button onClick={props.onClick} ref={props.intro ? surveyBtnRef : null}>
          {props.leftIconButton} {props.buttonTxt} {props.rightIconButton}
        </Button>
      )}
      {props.intro && (
        <div
          style={{
            transform: showSurveyFixedBtn
              ? 'translateY(0rem)'
              : 'translateY(4.375rem)',
          }}
          className={styles.surveyFixedBtn}
        >
          <Button onClick={props.onClick}>Inizia il questionario</Button>
        </div>
      )}
      {props.link && props.onClick === "howWork" && (
        <div className={styles.linkIcons}>
          <a href="#come-funziona" className="click">
            {props.iconLeftLink} <Text type="span">{props.link}</Text>{" "}
            {props.iconRigthLink}
          </a>
        </div>
      )}
      {props.link && props.onClick === "school" && (
        <div className={styles.linkWrapper}>
          <div
            className={styles.linkIcons}
            onClick={() => {
              window.open(
                "https://scuolapsicoterapiaistituzionale.it/",
                "_blank"
              );
            }}
          >
            <div className="click">
              {props.iconLeftLink} <Text type="span">{props.link}</Text>{" "}
              {props.iconRigthLink}
            </div>
          </div>
          <div
            className={styles.linkIcons}
            onClick={() => {
              window.open(
                "https://cssformazione.online/formazione.html",
                "_blank"
              );
            }}
          >
            <div className="click">
              {props.iconLeftLink}{" "}
              <Text type="span">Scopri i nostri corsi ECM</Text>{" "}
              {props.iconRigthLink}
            </div>
          </div>
        </div>
      )}
      {props.link && typeof props.onClick !== "string" && (
        <div className={styles.linkIcons} onClick={props.onClick}>
          <div className="click">
            {props.iconLeftLink} <Text type="span">{props.link}</Text>{" "}
            {props.iconRigthLink}
          </div>
        </div>
      )}
      {props.textStains &&
        stains?.map((stain, index) => {
          return (
            <Image key={index} className={styles.stain} src={stain} alt="" />
          );
        })}
    </div>
  );
};
