import styles from './Profile.module.scss';
import { trpc } from '../../utils/trpc';
import Emitter from '../../utils/eventemitter';
import routes from '../../routes';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../components/atoms/Avatar';
import { envs } from '../../utils/envs';
import Button from '../../components/atoms/Button';
import Modal from '../../components/organisms/Modal';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import addAccount from '../../theme/svg/add.svg';
import { useTherapistData } from '../../customHooks/therapistDataContext';
import { firstAppointmentExist } from '../../utils/checkAppointmentExist';
import { checkIf48HoursPassed } from '../../utils/checkIf48HoursPassed';

export const Profile = () => {
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [myTherapistToggle, setMyTherapistToggle] = useState(false);
  const {therapistData, setTherapistData} = useTherapistData();

  const navigate = useNavigate();

  const {data: userData}: any =
    trpc.mainService.user.getCurrentUserInfo.useQuery();

  const getChatsResponse = trpc.mainService.chat.getChats.useQuery();

  const isPatient = userData?.user.role === 'patient'

  const getMyTherapists: any = trpc.mainService.additionalAccounts.getAdditionalAccounts.useQuery(
    undefined,
    {enabled: myTherapistToggle}
  );

  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {patientId: userData?.user.id}, {enabled: !!userData}
  ).data

  const encodeB64 = (obj: object) => {
    try {
      return btoa(JSON.stringify(obj));
    } catch (err: any) {
      console.error('Encoding Error:', err);
      return '';
    }
  };

  const therapyTypes: any = Array.from(new Set(getMyTherapists?.data?.therapists?.map(item => item.therapyType)));

  const paramsObj = {
    disabledTypes: therapyTypes,
    sendNewSurvey: 'sendNewSurvey',
    therapistId: userData?.user?.therapistId,
    patientId: userData?.user?.id
  }

  const encodedParams = !loading && encodeB64(paramsObj);

  useEffect(() => {
    if (myTherapistToggle) {
      if (!getMyTherapists.isSuccess || !getChatsResponse.isSuccess || !userData) {
        setLoadingToggle(true);
        setLoading(true)
      } else if (getMyTherapists.isSuccess && getChatsResponse.isSuccess && userData) {
        setLoadingToggle(false);
        setLoading(false)
      }
    }
  }, [getMyTherapists.isSuccess, getChatsResponse.isSuccess, myTherapistToggle, userData]);

  useEffect(() => {
    if (userData) {
      if (!isPatient || (therapistData?.therapyType ?? userData?.user?.therapyType) === 'underage') {
        navigate(routes.Chat.path);
      } else {
        setMyTherapistToggle(true);
      }
    }
  }, [userData]);

  const handleChoseTherapist = async (account: any) => {
    const result = await firstAppointmentExist(getAppointments?.appointments, account.id);
    setTherapistData({
      therapistId: account.id,
      chat: {
        id: account?.chat?.id,
        therapyType: account?.chat?.therapyType,
        createdAt: account?.chat?.createdAt
      },
      therapyType: account?.therapyType,
      changeTherapist: account.changeTherapist,
      main: userData?.user?.therapistId === account.id
    })

    Emitter.emit('updateInfo');

    if (checkIf48HoursPassed(account?.chat) || result) {
      navigate(routes.Chat.path);
    } else {
      navigate(routes.PatientBookingFirstAppointment.path);
    }
  };

  const notificationMap: any = getChatsResponse?.data?.chats.reduce((acc, chat) => {
    acc[chat.id] = chat.unreadMessages;
    return acc;
  }, {});

  return (
    <div className={styles.profile}>
      {loading ? <Modal>
          <div className={styles.loadingSpinner}>
            <Spin
              className={styles.spinner}
              indicator={
                <LoadingOutlined
                  color={'#3576bb'}
                  style={{fontSize: 32}}
                  spin
                />
              }
            />
          </div>
        </Modal> :
        <>
          <div className={styles.accountsWrapper}>
            {getMyTherapists?.data?.therapists?.map((i, index) => {
              const unreadMessages = notificationMap[i.chat.id] || 0;
              return (
                <div key={index} onClick={() => handleChoseTherapist(i)}
                     className={`${styles.accountsCardWrapper} ${styles[i.therapyType]} ${i.id === therapistData?.therapistId ? styles.active : ''} `}
                >
                  {unreadMessages > 0 &&
                    <div className={`${styles.badge} ${styles[i.therapyType]}`}><span>{unreadMessages}</span></div>}
                  <div className={styles.accountsCardContainer}>
                    <div className={styles.profilePictureWrapper}>
                      <Avatar className={`${styles.profilePicture} ${styles[i.therapyType]}`}
                              avatarImg={`${envs.REACT_APP_S3_ENDPOINT}/pic-${i.id}?t=${Date.now()}`}/>
                    </div>
                    <div className={styles.cardInfo}>
                      <div className={styles.cardName}>{i.given_name} {i.family_name}</div>
                      <div className={styles.title}>{i.title}</div>
                      <Button variant="profile" className={styles[i.therapyType]}>Chat</Button>
                    </div>
                  </div>
                </div>
              )
            })}
            {getMyTherapists?.data?.therapists.length < 3 &&
              <div className={`${styles.accountsCardWrapper} ${styles.addAccount}`}
                   onClick={() => navigate(`${routes.Survey.links.start}?params=${encodedParams}`)}>
                <img src={addAccount} alt=""/>
                Aggiungi percorso
              </div>
            }
          </div>
          <div className={styles.buttonContainer}>
            <Button onClick={() => navigate(routes.Chat.path)}>
              Vai al menu
            </Button>
          </div>
        </>
      }
    </div>
  );
};
