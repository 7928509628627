import { useEffect, useState } from 'react';
import styles from "./Avatar.module.scss";
import AvatarImage from "theme/images/avatar.png";

export const Avatar = ({ className = "", avatarImg }: any) => {
  const [image, setImage] = useState<any>(avatarImg);

  useEffect(() => {
    setImage(avatarImg)
  }, [avatarImg]);

  return (
      <img
        className={`${styles.avatar} ${className}`}
        src={image || AvatarImage}
        alt="profile"
        loading="lazy"
        onError={() => {
          setImage(AvatarImage);
        }}
      />
  );
};
