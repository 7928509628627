import {Fragment, useEffect, useRef, useState} from "react";
import Button from "../../atoms/Button";
import InfoBox from "../../atoms/InfoBox";
import styles from "./UserPreferences.module.scss";
import Modal from "../Modal";
import ModalCurrentTherapist from "../ModalCurrentTherapist";
import FormHeader from "../../atoms/FormHeader";
import {trpc} from "../../../utils/trpc";
import Input from "../../atoms/Input";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import routes from "routes";
import {useAppDispatch} from "customHooks/reduxHooks";
import {setInfo} from "store/slices/infoSlice";
import {useFormik} from "formik";
import * as yup from "yup";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import {analyticsService} from "../../../utils/analyticsService";
import TherapistFeedbackModal from "../TherapistFeedbackModal";
import { raiseException } from "../../../utils/raiseException";
import trimString from "../../../utils/trimString";
import it from "antd/es/date-picker/locale/it_IT"
import Emitter from "../../../utils/eventemitter";
import { useTherapistData } from '../../../customHooks/therapistDataContext';

const yearInMillis = 365 * 24 * 60 * 60 * 1000;
const minDate = new Date(Date.now() - 120 * yearInMillis);
const maxDate = new Date(Date.now() - 18 * yearInMillis);
const maxDateOther = new Date(Date.now() - 24 * 60 * 60 * 1000);

export const UserPreferences = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const {state} = location;
	const isMobile = window.matchMedia('(max-width: 47.9375rem)').matches;

	const dispatch = useAppDispatch();
	const [userGender, setUserGender] = useState("");
	const [therapist, setTherapist] = useState<any>(null);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
	const [otherDateOfBirth, setOtherDateOfBirth] = useState<Date | null>(null);
	const {therapistData} = useTherapistData();

	useEffect(() => {
		analyticsService({
			'event': 'virtualPageview',
			'pageUrl': '/dashboard/profilo/preferenze',
			'pageTitle': 'Patient Preferences'
		});
	}, []);

	const searchParams = useSearchParams()[0];
	let informedConsentParam = searchParams.get('consenso-informato');

	const [informedConsentModalIsOpen, setInformedConsentModalIsOpen] =
		useState(false);
	const [signatureModalIsOpen, setSignatureModalIsOpen] = useState(false);
	const [showCurrentTherapistModal, setShowCurrentTherapistModal] =
		useState(false);
	const [changePreferencesModalIsOpen, setChangePreferencesModalIsOpen] =
		useState(false);
	const [informedConsetIsCompiled, setInformedConsentIsCompiled] =
		useState(false);
	const [informedConsentData, setInformedConsentData] = useState<{
		givenName: string;
		familyName: string;
		taxId: string;
		placeOfBirth: string;
		dateOfBirth: Date | null;
		residence: string;
		address: string;
	}>({
		givenName: "",
		familyName: "",
		taxId: "",
		placeOfBirth: "",
		dateOfBirth: null,
		residence: "",
		address: "",
	});

	const [otherInformedConsentData, setOtherInformedConsentData] = useState<{
		givenName: string;
		familyName: string;
		taxId: string;
		placeOfBirth: string;
		dateOfBirth: Date | null;
		residence: string;
		address: string;
	}>({
		givenName: "",
		familyName: "",
		taxId: "",
		placeOfBirth: "",
		dateOfBirth: null,
		residence: "",
		address: "",
	});

	const firstPageSubmitRef = useRef<HTMLButtonElement>(null);
	const secondPageSubmitRef = useRef<HTMLButtonElement>(null);

	const formik = useFormik({
		initialValues: {
			givenName: "",
			familyName: "",
			taxId: "",
			placeOfBirth: "",
			dateOfBirth: null as Date | null,
			residence: "",
			address: "",
		},
		validationSchema: yup.object().shape({
			givenName: yup.string().required("Il nome è un campo obbligatorio"),
			familyName: yup.string().required("Il cognome è un campo obbligatorio"),
			taxId: yup.string().required("Il codice fiscale è un campo obbligatorio"),
			placeOfBirth: yup
				.string()
				.required("Il luogo di nascita è un campo obbligatorio"),
			dateOfBirth: yup
				.date()
				.nullable()
				.transform((value, originalValue) => {
					return originalValue ? new Date(originalValue) : null;
				})
				.min(minDate, "Data troppo lontana")
				.max(maxDate, "Deve essere maggiorenne")
				.required("La data di nascita è un campo obbligatorio"),
			residence: yup.string().required("La residenza è un campo obbligatorio"),
			address: yup.string().required("La via è un campo obbligatorio"),
		}),
		onSubmit: (values, actions) => {
			setInformedConsentData({
				givenName: values.givenName,
				familyName: values.familyName,
				taxId: values.taxId,
				placeOfBirth: values.placeOfBirth,
				dateOfBirth: values.dateOfBirth as any,
				residence: values.residence,
				address: values.address,
			});

			if ((therapistData?.therapyType ?? userInfo.data?.user?.therapyType) !== 'underage') {
				setInformedConsentModalIsOpen(false);
				setSignatureModalIsOpen(true);
			} else {
				secondPageSubmitRef.current?.click();
			}
		},
	});

	const underageFormik = useFormik({
		initialValues: {
			givenName: "",
			familyName: "",
			taxId: "",
			placeOfBirth: "",
			dateOfBirth: null as Date | null,
			residence: "",
			address: "",
		},
		validationSchema: yup.object().shape({
			givenName: yup.string().required("Il nome è un campo obbligatorio"),
			familyName: yup.string().required("Il cognome è un campo obbligatorio"),
			taxId: yup.string().required("Il codice fiscale è un campo obbligatorio"),
			placeOfBirth: yup
				.string()
				.required("Il luogo di nascita è un campo obbligatorio"),
			dateOfBirth: yup
				.date()
				.nullable()
				.transform((value, originalValue) => {
					return originalValue ? new Date(originalValue) : null;
				})
				.min(maxDate, "Deve essere minorenne")
				.max(maxDateOther, "Data di nascita invalida")
				.required("La data di nascita è un campo obbligatorio"),
			residence: yup.string().required("La residenza è un campo obbligatorio"),
			address: yup.string().required("La via è un campo obbligatorio"),
		}),
		onSubmit: (values, actions) => {
			setOtherInformedConsentData({
				givenName: values.givenName,
				familyName: values.familyName,
				taxId: values.taxId,
				placeOfBirth: values.placeOfBirth,
				dateOfBirth: values.dateOfBirth as any,
				residence: values.residence,
				address: values.address,
			});

			setInformedConsentModalIsOpen(false);
			setSignatureModalIsOpen(true);
		},
	});

	const [agreeConsent, setAgreeConsent] = useState(false);
	const [changeMotive, setChangeMotive] = useState("");
	const [userTherapy, setUserTherapy] = useState<
		"single" | "couple" | "underage" | "psychiatry"
	>("single");

	const getMyTherapist = trpc.mainService.patient.getMyTherapist.useQuery(
		{therapistId: therapistData?.therapistId || undefined}
	).data?.therapist;


	const userInfo = trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined});

	const {data: informedConsentResponse, isLoading: isInformedConsentLoading} =
		trpc.mainService.patient.getinformedConsent.useQuery({
			accountTherapyType: therapistData?.therapyType || undefined,
			additionalTherapistId: therapistData?.therapistId || undefined
		});

	const submitInformedConsent =
		trpc.mainService.patient.submitInformedConsent.useMutation();

	const sendChangeTherapistFeedback =
		trpc.mainService.patient.sendChangeTherapistFeedback.useMutation();

	const sendInformedConsent =
		trpc.mainService.patient.sendInformedConsent.useMutation();

	const handleDateChange = (date: any) => {
		setDateOfBirth(date ? date.toDate() : null);
		formik.setFieldValue("dateOfBirth", date ? date.toDate() : null);
	};

	const handleOtherDateChange = (date: any) => {
		setOtherDateOfBirth(date ? date.toDate() : null);
		underageFormik.setFieldValue("dateOfBirth", date ? date.toDate() : null);
	};

	const disableDate = (min: Date, max: Date) => (current: any) => {
		return current && (current < dayjs(min) || current > dayjs(max));
	};


	const onSubmitInformedConsent = () => {
		if (formIsCompiled()) {
			const other =
				userTherapy !== "single" && userTherapy !== "couple" && userTherapy !== "psychiatry"
					? {
						givenName: trimString(otherInformedConsentData.givenName),
						familyName: trimString(otherInformedConsentData.familyName),
						taxId: trimString(otherInformedConsentData.taxId),
						dateOfBirth: otherInformedConsentData.dateOfBirth!,
						placeOfBirth: trimString(otherInformedConsentData.placeOfBirth),
						residence: trimString(otherInformedConsentData.residence),
						address: trimString(otherInformedConsentData.address),
					}
					: undefined;
			submitInformedConsent
				.mutateAsync({
					givenName: trimString(informedConsentData.givenName),
					familyName: trimString(informedConsentData.familyName),
					taxId: trimString(informedConsentData.taxId),
					dateOfBirth: informedConsentData.dateOfBirth!,
					placeOfBirth: trimString(informedConsentData.placeOfBirth),
					residence: trimString(informedConsentData.residence),
					address: trimString(informedConsentData.address),
					therapistId: therapistData?.therapistId,
					accountTherapyType: therapistData?.therapyType,
					other,
				})
				.then(async (data) => {
					setSignatureModalIsOpen(false);
					await userInfo.refetch();
					Emitter.emit('updateInfo');
					navigate(routes.Chat.path);
				})
				.catch((e) => {
					raiseException(e);
					dispatch(
						setInfo({
							variant: "error",
							text: "Prova a ricaricare la pagina e verifica di essere connsso a internet",
							title: "Si è verificato un errore",
						})
					);
				});
		} else {
			console.log("SERVIZIO NON CHIAMATO");
		}
	};

	const onSendChangeTherapistFeedback = async (elementToChange: "therapist" | "preferences") => {
		try {
			await sendChangeTherapistFeedback.mutateAsync({feedback: changeMotive})
			navigate(routes.Survey.links.start, {
				state: {
					user: userInfo?.data?.user,
					sendNewSurvey: "sendNewSurvey",
				},
			});
		} catch (error: any) {
			raiseException(error);
			console.error("ERROR", error?.shape?.message)
		}
	};

	const formIsCompiled = () => {
		return (
			informedConsentData.givenName.trim() !== "" &&
			informedConsentData.familyName.trim() !== "" &&
			informedConsentData.taxId.trim() !== "" &&
			informedConsentData.placeOfBirth.trim() !== "" &&
			informedConsentData.dateOfBirth &&
			informedConsentData.residence.trim() !== "" &&
			informedConsentData.address.trim() !== "" &&
			(userTherapy === "single" ||
				userTherapy === "couple" ||
				userTherapy === "psychiatry" ||
				(otherInformedConsentData.givenName.trim() !== "" &&
					otherInformedConsentData.familyName.trim() !== "" &&
					otherInformedConsentData.taxId.trim() !== "" &&
					otherInformedConsentData.placeOfBirth.trim() !== "" &&
					otherInformedConsentData.dateOfBirth &&
					otherInformedConsentData.residence.trim() !== "" &&
					otherInformedConsentData.address.trim() !== ""))
		);
	};

	useEffect(() => {
		if (getMyTherapist) {
			setTherapist(getMyTherapist);
			if (state?.informedConsentIsOpen) {
				setInformedConsentModalIsOpen(true);
			} else if (informedConsentParam && informedConsentParam === 'open') {
				setInformedConsentModalIsOpen(true);
			}
		}
	}, [getMyTherapist]);

	useEffect(() => {
		if (userInfo.data) {
			setUserGender(userInfo.data.user.gender!);
			setUserTherapy(therapistData?.therapyType);
			if (userTherapy !== "underage") {
				setInformedConsentData({
					...informedConsentData,
					givenName: userInfo.data.user.given_name!,
					familyName: userInfo.data.user.family_name!,
				});
			}
		}
	}, [userInfo.data]);

	useEffect(() => {
		if (
			informedConsentResponse?.type === "single" ||
			informedConsentResponse?.type === "couple" ||
			informedConsentResponse?.type === "psychiatry"
		) {
			setInformedConsentIsCompiled(
				informedConsentResponse.informedConsent !== null
			);
		} else if (informedConsentResponse?.type === "underage") {
			setInformedConsentIsCompiled(
				informedConsentResponse.informedConsent !== null &&
				informedConsentResponse.otherInformedConsent !== null
			);
		}
	}, [informedConsentResponse]);

	return (
		<div className={styles.userPreferences}>
			{informedConsentModalIsOpen && (
				<Modal close={() => {
					setInformedConsentModalIsOpen(false);
				}}>
					<div className={styles.informedConsent} onClick={e => {
						e.stopPropagation()
					}}>
						<FormHeader
							title="Consenso informato"
							closeModal={() => setInformedConsentModalIsOpen(false)}
							closeButton
						/>
						<div className={styles.TherapistParty}>
							{userTherapy === "underage"
								? "Il genitore:"
								: "Il Paziente:"}
						</div>
						<form
							className={styles.inputWrapper}
							onSubmit={formik.handleSubmit}
						>
							<div className={styles.inputRow}>
								<Input
									label="Nome"
									name="givenName"
									value={formik.values.givenName}
									onChange={formik.handleChange}
									error={
										(formik.errors.givenName &&
											formik.touched.givenName &&
											formik.errors.givenName) as string
									}
								/>
								<Input
									label="Cognome"
									name="familyName"
									value={formik.values.familyName}
									onChange={formik.handleChange}
									error={
										(formik.errors.familyName &&
											formik.touched.familyName &&
											formik.errors.familyName) as string
									}
								/>
							</div>
							<div className={styles.inputRow}>
								<Input
									label="Luogo di nascita"
									name="placeOfBirth"
									value={formik.values.placeOfBirth}
									onChange={formik.handleChange}
									error={
										(formik.errors.placeOfBirth &&
											formik.touched.placeOfBirth &&
											formik.errors.placeOfBirth) as string
									}
								/>
								<div
									className={`${styles.smallerInput} ${styles.datePicker} ${
										formik.errors.dateOfBirth &&
										formik.touched.dateOfBirth &&
										formik.errors.dateOfBirth
											? `${styles.error} error`
											: ''
									}`}
								>
									<span className={styles.label}>Data di nascita</span>
									<DatePicker
										locale={it}
										inputReadOnly={isMobile}
										value={dateOfBirth ? dayjs(dateOfBirth) : null}
										defaultPickerValue={dayjs(maxDate)}
										onChange={handleDateChange}
										showNow={false}
										disabledDate={disableDate(minDate, maxDate)}
										format={{
											format: 'DD/MM/YYYY',
											type: !isMobile ? 'mask' : undefined,
										}}
										status={formik.errors.dateOfBirth && formik.touched.dateOfBirth ? 'error' : ''}
									/>
									<span className={styles.label}>
                    {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
											formik.errors.dateOfBirth as string
										)}
                  </span>
								</div>
							</div>
							<div className={styles.inputRow}>
								<Input
									label="Codice fiscale"
									name="taxId"
									value={formik.values.taxId}
									onChange={formik.handleChange}
									error={
										(formik.errors.taxId &&
											formik.touched.taxId &&
											formik.errors.taxId) as string
									}
								/>
							</div>
							<div className={styles.inputRow}>
								<Input
									label="Città di residenza"
									name="residence"
									value={formik.values.residence}
									onChange={formik.handleChange}
									error={
										(formik.errors.residence &&
											formik.touched.residence &&
											formik.errors.residence) as string
									}
								/>
								<Input
									label="Indirizzo e civico"
									name="address"
									value={formik.values.address}
									onChange={formik.handleChange}
									error={
										(formik.errors.address &&
											formik.touched.address &&
											formik.errors.address) as string
									}
								/>
							</div>
							<button
								ref={firstPageSubmitRef}
								type="submit"
								className={styles.hiddenButton}
							></button>
						</form>
						{userTherapy === "underage" && (
							<Fragment>
								{userTherapy === "underage" && (
									<div className={styles.TherapistParty}>
										nell’esercizio della responsabilità genitoriale sulla/sul
										minore (di seguito, il <span>Paziente</span>):
									</div>
								)}
								<form
									className={styles.inputWrapper}
									onSubmit={underageFormik.handleSubmit}
								>
									<div className={styles.inputRow}>
										<Input
											label="Nome"
											name="givenName"
											value={underageFormik.values.givenName}
											onChange={underageFormik.handleChange}
											error={
												(underageFormik.errors.givenName &&
													underageFormik.touched.givenName &&
													underageFormik.errors.givenName) as string
											}
										/>
										<Input
											label="Cognome"
											name="familyName"
											value={underageFormik.values.familyName}
											onChange={underageFormik.handleChange}
											error={
												(underageFormik.errors.familyName &&
													underageFormik.touched.familyName &&
													underageFormik.errors.familyName) as string
											}
										/>
									</div>
									<div className={styles.inputRow}>
										<Input
											label="Luogo di nascita"
											name="placeOfBirth"
											value={underageFormik.values.placeOfBirth}
											onChange={underageFormik.handleChange}
											error={
												(underageFormik.errors.placeOfBirth &&
													underageFormik.touched.placeOfBirth &&
													underageFormik.errors.placeOfBirth) as string
											}
										/>
										<div
											className={`${styles.smallerInput} ${styles.datePicker} ${
												underageFormik.errors.dateOfBirth &&
												underageFormik.touched.dateOfBirth &&
												underageFormik.errors.dateOfBirth
													? `${styles.error} error`
													: ""
											}`}
										>
											<span className={styles.label}>Data di nascita</span>
											<DatePicker
												locale={it}
												inputReadOnly={isMobile}
												value={otherDateOfBirth ? dayjs(otherDateOfBirth) : null}
												onChange={handleOtherDateChange}
												disabledDate={disableDate(maxDate, maxDateOther)}
												defaultPickerValue={dayjs(maxDate)}
												showNow={false}
												format={{
													format: 'DD/MM/YYYY',
													type: !isMobile ? 'mask' : undefined,
												}}
												status={underageFormik.errors.dateOfBirth && underageFormik.touched.dateOfBirth ? "error" : ""}
											/>
											<span className={styles.label}>
                        {underageFormik.errors.dateOfBirth && underageFormik.touched.dateOfBirth && (
													underageFormik.errors.dateOfBirth as string
												)}
                      </span>
										</div>
									</div>
									<div className={styles.inputRow}>
										<Input
											label="Codice fiscale"
											name="taxId"
											value={underageFormik.values.taxId}
											onChange={underageFormik.handleChange}
											error={
												(underageFormik.errors.taxId &&
													underageFormik.touched.taxId &&
													underageFormik.errors.taxId) as string
											}
										/>
									</div>
									<div className={styles.inputRow}>
										<Input
											label="Città di residenza"
											name="residence"
											value={underageFormik.values.residence}
											onChange={underageFormik.handleChange}
											error={
												(underageFormik.errors.residence &&
													underageFormik.touched.residence &&
													underageFormik.errors.residence) as string
											}
										/>
										<Input
											label="Indirizzo e civico"
											name="address"
											value={underageFormik.values.address}
											onChange={underageFormik.handleChange}
											error={
												(underageFormik.errors.address &&
													underageFormik.touched.address &&
													underageFormik.errors.address) as string
											}
										/>
									</div>
									<button
										ref={secondPageSubmitRef}
										type="submit"
										className={styles.hiddenButton}
									></button>
								</form>
							</Fragment>
						)}
						<div className={styles.TherapistParty}>
							Affidandosi{" "}
							{therapist?.gender === "female" ? "alla dott.ssa" : "al dott."}{" "}
							{therapist?.given_name} {therapist?.family_name}{" "}
							{therapist?.gender === "female" ? "Iscritta" : "Iscritto"}{" "}
							{userTherapy === "psychiatry" ? 'all’Ordine dei Medici di' : 'all’Albo degli Psicologi della Regione'}{" "}
              {therapist?.licenceRegisterPlace}, assicurato con Polizza RC
							professionale sottoscritta con {therapist.licenceRegisterWith} n.
              {therapist?.licenceRegisterNumber} (di seguito, il{" "}
							<span>Professionista</span>)
						</div>
						<div className={styles.btnWrapper}>
							<Button
								variant="secondary"
								onClick={() => setInformedConsentModalIsOpen(false)}
							>
								Annulla
							</Button>
							<Button
								onClick={async () => {
									const result = await formik.validateForm();
									const underageResult = await underageFormik.validateForm();
									if (Object.keys(result).length === 0) {
										firstPageSubmitRef.current?.click();
									} else {
										formik.setErrors({
											...result,
										});
										formik.setTouched({
											givenName: true,
											familyName: true,
											taxId: true,
											placeOfBirth: true,
											dateOfBirth: true,
											residence: true,
											address: true,
										});
										underageFormik.setErrors({
											...underageResult,
										});
										underageFormik.setTouched({
											givenName: true,
											familyName: true,
											taxId: true,
											placeOfBirth: true,
											dateOfBirth: true,
											residence: true,
											address: true,
										});
									}
								}}
							>
								Avanti
							</Button>
						</div>
					</div>
				</Modal>
			)}
			{(showFeedbackModal && userInfo?.isSuccess) &&
				<TherapistFeedbackModal
					myTherapist={getMyTherapist}
					userInfo={userInfo?.data?.user}
					modalClose={() => setShowFeedbackModal(false)}
				/>
			}
			{signatureModalIsOpen && (
				<Modal close={() => {
					setSignatureModalIsOpen(false);
				}}>
					<div className={styles.informedConsent} onClick={e => {
						e.stopPropagation()
					}}>
						<FormHeader
							title="Consenso informato"
							closeModal={() => setSignatureModalIsOpen(false)}
							closeButton
						/>
						<div className={styles.information}>
							{userTherapy === "single"
							&& informedConsentResponse?.informedConsent ? (
								<div className={styles.TherapistParty}>
									Il sottoscritto{" "}
									{informedConsentResponse.informedConsent.givenName}{" "}
									{informedConsentResponse.informedConsent.familyName},
									nato/a a{" "}
									{informedConsentResponse.informedConsent.placeOfBirth} il{" "}
									{new Date(
										informedConsentResponse.informedConsent.dateOfBirth
									).toLocaleDateString("it", {
										day: "2-digit",
										month: "2-digit",
										year: "numeric",
									})}
									, C.F.: {informedConsentResponse.informedConsent.taxId},
									residente a{" "}
									{informedConsentResponse.informedConsent.residence}, in
									via {informedConsentResponse.informedConsent.cap} (di
									seguito, il <span>Paziente</span>)
								</div>
							) : userTherapy === 'couple' && informedConsentResponse?.informedConsent ? (
								<div className={styles.TherapistParty}>
									Il sottoscritto{' '}
									{informedConsentResponse.informedConsent.givenName}{' '}
									{informedConsentResponse.informedConsent.familyName},
									nato/a a{' '}
									{informedConsentResponse.informedConsent.placeOfBirth} il{' '}
									{new Date(
										informedConsentResponse.informedConsent.dateOfBirth
									).toLocaleDateString('it', {
										day: '2-digit',
										month: '2-digit',
										year: 'numeric',
									})}
									, C.F.: {informedConsentResponse.informedConsent.taxId},
									residente a{' '}
									{informedConsentResponse.informedConsent.residence}, in
									via {informedConsentResponse.informedConsent.cap}
									{informedConsentResponse.otherInformedConsent && (
										<Fragment>
											{' '}
											e Il sottoscritto{' '}
											{
												informedConsentResponse.otherInformedConsent
													.givenName
											}{' '}
											{
												informedConsentResponse.otherInformedConsent
													.familyName
											}
											, nato/a a{' '}
											{
												informedConsentResponse.otherInformedConsent
													.placeOfBirth
											}{' '}
											il{' '}
											{new Date(
												informedConsentResponse.otherInformedConsent.dateOfBirth
											).toLocaleDateString('it', {
												day: '2-digit',
												month: '2-digit',
												year: 'numeric',
											})}
											, C.F.:{' '}
											{informedConsentResponse.otherInformedConsent.taxId},
											residente a{' '}
											{
												informedConsentResponse.otherInformedConsent
													.residence
											}
											, in via{' '}
											{informedConsentResponse.otherInformedConsent.cap} (di
											seguito, i <span>Pazienti</span>)
										</Fragment>
									)}
								</div>
							) : userTherapy === "underage" &&
								informedConsentResponse?.otherInformedConsent ? (
									<div className={styles.TherapistParty}>
										Il sottoscritto{" "}
										{informedConsentResponse.otherInformedConsent.givenName}{" "}
										{informedConsentResponse.otherInformedConsent.familyName},
										nato/a a{" "}
										{
											informedConsentResponse.otherInformedConsent
												.placeOfBirth
										}{" "}
										il{" "}
										{new Date(
											informedConsentResponse.otherInformedConsent.dateOfBirth
										).toLocaleDateString("it", {
											day: "2-digit",
											month: "2-digit",
											year: "numeric",
										})}
										, C.F.:{" "}
										{informedConsentResponse.otherInformedConsent.taxId},
										residente a{" "}
										{informedConsentResponse.otherInformedConsent.residence},
										in via {informedConsentResponse.otherInformedConsent.cap}
										{informedConsentResponse.otherInformedConsentParent && (
											<Fragment>
												{" "}
												e Il sottoscritto{" "}
												{
													informedConsentResponse.otherInformedConsentParent
														.givenName
												}{" "}
												{
													informedConsentResponse.otherInformedConsentParent
														.familyName
												}
												, nato/a a{" "}
												{
													informedConsentResponse.otherInformedConsentParent
														.placeOfBirth
												}{" "}
												il{" "}
												{new Date(
													informedConsentResponse.otherInformedConsentParent.dateOfBirth
												).toLocaleDateString("it", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
												})}
												, C.F.:{" "}
												{
													informedConsentResponse.otherInformedConsentParent
														.taxId
												}
												, residente a{" "}
												{
													informedConsentResponse.otherInformedConsentParent
														.residence
												}
												, in via{" "}
												{
													informedConsentResponse.otherInformedConsentParent
														.cap
												}
											</Fragment>
										)}
										{informedConsentResponse.informedConsent && (
											<Fragment>
												{" "}
												nell’esercizio della responsabilità genitoriale
												sulla/sul minore (di seguito, il <span>Paziente</span>
												) {informedConsentResponse.informedConsent.givenName}{" "}
												{informedConsentResponse.informedConsent.familyName},
												nato/a a{" "}
												{informedConsentResponse.informedConsent.placeOfBirth}{" "}
												il{" "}
												{new Date(
													informedConsentResponse.informedConsent.dateOfBirth
												).toLocaleDateString("it", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
												})}
												, C.F.:{" "}
												{informedConsentResponse.informedConsent.taxId},
												residente a{" "}
												{informedConsentResponse.informedConsent.residence},
												in via {informedConsentResponse.informedConsent.cap}
											</Fragment>
										)}
									</div>
								) : userTherapy === "psychiatry" &&
									informedConsentResponse?.informedConsent && (
										<div className={styles.TherapistParty}>
											Il sottoscritto{" "}
											{informedConsentResponse.informedConsent.givenName}{" "}
											{informedConsentResponse.informedConsent.familyName},
											nato/a a{" "}
											{informedConsentResponse.informedConsent.placeOfBirth} il{" "}
											{new Date(
												informedConsentResponse.informedConsent.dateOfBirth
											).toLocaleDateString("it", {
												day: "2-digit",
												month: "2-digit",
												year: "numeric",
											})}
											, C.F.: {informedConsentResponse.informedConsent.taxId},
											residente a{" "}
											{informedConsentResponse.informedConsent.residence}, in
											via {informedConsentResponse.informedConsent.cap} (di
											seguito, il <span>Paziente</span>),
										</div>
							)}
							<br></br>
							{informedConsentResponse?.informedConsent && (
								<Fragment>
									<div className={styles.TherapistParty}>
										Affidandosi{" "}
										{therapist?.gender === "female"
											? "alla dott.ssa"
											: "al dott."}{" "}
										{therapist?.given_name} {therapist?.family_name}{" "}
										{therapist?.gender === "female" ? "Iscritta" : "Iscritto"}{" "}
										{userTherapy === "psychiatry" ? 'all’Ordine dei Medici di' : 'all’Albo degli Psicologi della Regione'}{" "}
										{therapist.licenceRegisterPlace}, assicurato con Polizza RC
										professionale sottoscritta con{" "}
										{therapist.licenceRegisterWith} n.
										{therapist.licenceRegisterNumber} (di seguito, il{" "}
										<span>Professionista</span>)
									</div>
									<br></br>
								</Fragment>
							)}
							<span>
                {userTherapy === "couple"
									? "I Pazienti sono informati che:"
									: "Il Paziente è informato che:"}
              </span>
							{userTherapy === "single" ? (
								<ol>
									<li>
										il Professionista è strettamente tenuto ad attenersi al
										Codice Deontologico degli Psicologi Italiani;
									</li>
									<li>
										la prestazione offerta riguarda la consulenza in ambito
										psicologico o psicoterapeutico attraverso colloqui in
										modalità videochiamata tramite l’utilizzo della piattaforma
										online “Mymentis”, nelle date concordate tra Paziente e
										Professionista;
									</li>
									<li>
										la prestazione è da considerarsi complessa in quanto è
										previsto il coinvolgimento di altri professionisti quali
										psicologi e/o psicoterapeuti;
									</li>
									<li>
										la prestazione è finalizzata ad attività professionali di
										promozione e tutela della salute e del benessere di persone,
										gruppi, organismi sociali e comunità. Comprende, di norma,
										tutte le attività previste ai sensi dell&#39;art. 1 della L.
										n.56/1989;
									</li>
									<li>
										per il conseguimento dell’obiettivo saranno utilizzati
										prevalentemente gli strumenti gestionali, di messaggistica e
										videochiamata disponibili sulla piattaforma online
										“Mymentis”;
									</li>
									<li>
										la durata globale dell’intervento sarà definita di comune
										accordo tra il Paziente e il Professionista;
									</li>
									<li>
										in qualsiasi momento è possibile interrompere il rapporto
										comunicando alla/al Professionista la volontà di
										interruzione;
									</li>
									<li>
										{therapist?.gender === 'male' ? 'il' : 'la'} Professionista può valutare ed eventualmente proporre
										l’interruzione del rapporto quando constata che non vi sia
										alcun beneficio dall’intervento e non è ragionevolmente
										prevedibile che ve ne saranno dal proseguimento dello
										stesso. Se richiesto può fornire le informazioni necessarie
										a ricercare altri e più adatti interventi (art. 27 del
										Codice Deontologico degli Psicologi Italiani);
									</li>
									<li>
										le Parti sono tenute alla scrupolosa osservanza delle date e
										degli orari degli appuntamenti che vengono concordati oppure
										all’inizio di ogni rapporto di consulenza e/o di
										psicoterapia. In caso di sopravvenuta impossibilità di
										rispettare l’appuntamento fissato, la Parte impossibilitata
										è tenuta a darne notizia all’altra in tempi congrui.
										Entrambe le Parti si impegnano a rendere attivi e
										raggiungibili i propri recapiti rispettivamente forniti;
									</li>
									<li>
										il compenso da corrispondere per la prestazione
										professionale psicologica è indicato sulla piattaforma
										online “Mymentis”. Si precisa che il compenso non può essere
										condizionato all’esito o ai risultati dell’intervento
										professionale.;
									</li>
									<li>
										Le attività di trattamento dei dati personali vengono
										effettuate in conformità con le disposizioni del Reg. UE
										2016/679 e del d.lgs. n. 196/2003 come meglio specificato
										nell’informativa privacy consegnata al Paziente.
									</li>
								</ol>
							) : userTherapy === "couple" ? (
								<ol>
									<li>
										il Professionista è strettamente tenuto ad attenersi al
										Codice Deontologico degli Psicologi Italiani;
									</li>
									<li>
										la prestazione offerta riguarda la consulenza in ambito
										psicologico o psicoterapeutico attraverso colloqui in
										modalità videochiamata tramite l’utilizzo della piattaforma
										online “Mymentis”, nelle date concordate tra Paziente e
										Professionista;
									</li>
									<li>
										la prestazione è da considerarsi complessa in quanto è
										previsto il coinvolgimento di altri professionisti quali
										psicologi e/o psicoterapeuti;
									</li>
									<li>
										la prestazione è finalizzata ad attività professionali di
										promozione e tutela della salute e del benessere di persone,
										gruppi, organismi sociali e comunità. Comprende, di norma,
										tutte le attività previste ai sensi dell&#39;art. 1 della L.
										n.56/1989;
									</li>
									<li>
										per il conseguimento dell’obiettivo saranno utilizzati
										prevalentemente gli strumenti gestionali, di messaggistica e
										videochiamata disponibili sulla piattaforma online
										“Mymentis”;
									</li>
									<li>
										la durata globale dell’intervento sarà definita di comune
										accordo tra i Pazienti e il Professionista;
									</li>
									<li>
										in qualsiasi momento è possibile interrompere il rapporto
										comunicando alla/al Professionista la volontà di
										interruzione;
									</li>
									<li>
										{therapist?.gender === 'male' ? 'il' : 'la'} Professionista può valutare ed eventualmente proporre
										l’interruzione del rapporto quando constata che non vi sia
										alcun beneficio dall’intervento e non è ragionevolmente
										prevedibile che ve ne saranno dal proseguimento dello
										stesso. Se richiesto può fornire le informazioni necessarie
										a ricercare altri e più adatti interventi (art. 27 del
										Codice Deontologico degli Psicologi Italiani);
									</li>
									<li>
										le Parti sono tenute alla scrupolosa osservanza delle date e
										degli orari degli appuntamenti che vengono concordati oppure
										all’inizio di ogni rapporto di consulenza e/o di
										psicoterapia. In caso di sopravvenuta impossibilità di
										rispettare l’appuntamento fissato, la Parte impossibilitata
										è tenuta a darne notizia all’altra in tempi congrui.
										Entrambe le Parti si impegnano a rendere attivi e
										raggiungibili i propri recapiti rispettivamente forniti;
									</li>
									<li>
										il compenso da corrispondere per la prestazione
										professionale psicologica è indicato sulla piattaforma
										online “Mymentis”. Si precisa che il compenso non può essere
										condizionato all’esito o ai risultati dell’intervento
										professionale.;
									</li>
									<li>
										Le attività di trattamento dei dati personali vengono
										effettuate in conformità con le disposizioni del Reg. UE
										2016/679 e del d.lgs. n. 196/2003 come meglio specificato
										nell’informativa privacy consegnata ai Pazienti.
									</li>
								</ol>
							) : userTherapy === "underage" ? (
									<ol>
										<li>
											il Professionista è strettamente tenuto ad attenersi al
											Codice Deontologico degli Psicologi Italiani;
										</li>
										<li>
											la prestazione offerta riguarda la consulenza in ambito
											psicologico o psicoterapeutico attraverso colloqui in
											modalità videochiamata tramite l’utilizzo della
											piattaforma online “Mymentis”, nelle date concordate tra
											Paziente e Professionista;
										</li>
										<li>
											la prestazione è da considerarsi complessa in quanto è
											previsto il coinvolgimento di altri professionisti quali
											psicologi e/o psicoterapeuti;
										</li>
										<li>
											la prestazione è finalizzata ad attività professionali di
											promozione e tutela della salute e del benessere di
											persone, gruppi, organismi sociali e comunità. Comprende,
											di norma, tutte le attività previste ai sensi
											dell&#39;art. 1 della L. n.56/1989;
										</li>
										<li>
											per il conseguimento dell’obiettivo saranno utilizzati
											prevalentemente gli strumenti gestionali, di messaggistica
											e videochiamata disponibili sulla piattaforma online
											“Mymentis”;
										</li>
										<li>
											la durata globale dell’intervento sarà definita di comune
											accordo tra il Paziente e il Professionista;
										</li>
										<li>
											in qualsiasi momento è possibile interrompere il rapporto
											comunicando alla/al Professionista la volontà di
											interruzione;
										</li>
										<li>
											{therapist?.gender === 'male' ? 'il' : 'la'} Professionista può valutare ed eventualmente
											proporre l’interruzione del rapporto quando constata che
											non vi sia alcun beneficio dall’intervento e non è
											ragionevolmente prevedibile che ve ne saranno dal
											proseguimento dello stesso. Se richiesto può fornire le
											informazioni necessarie a ricercare altri e più adatti
											interventi (art. 27 del Codice Deontologico degli
											Psicologi Italiani);
										</li>
										<li>
											le Parti sono tenute alla scrupolosa osservanza delle date
											e degli orari degli appuntamenti che vengono concordati
											oppure all’inizio di ogni rapporto di consulenza e/o di
											psicoterapia. In caso di sopravvenuta impossibilità di
											rispettare l’appuntamento fissato, la Parte
											impossibilitata è tenuta a darne notizia all’altra in
											tempi congrui. Entrambe le Parti si impegnano a rendere
											attivi e raggiungibili i propri recapiti rispettivamente
											forniti;
										</li>
										<li>
											il compenso da corrispondere per la prestazione
											professionale psicologica è indicato sulla piattaforma
											online “Mymentis”. Si precisa che il compenso non può
											essere condizionato all’esito o ai risultati
											dell’intervento professionale.;
										</li>
										<li>
											Le attività di trattamento dei dati personali vengono
											effettuate in conformità con le disposizioni del Reg. UE
											2016/679 e del d.lgs. n. 196/2003 come meglio specificato
											nell’informativa privacy consegnata al Paziente.
										</li>
									</ol>
							) : (userTherapy === "psychiatry") && (
									<ol>
										<li>
											il Professionista è strettamente tenuto ad attenersi al Codice di Deontologia Medica;
										</li>
										<li>
											le prestazioni professionali specialistiche nella branca di
											Psichiatria sono rese attraverso colloqui in modalità videochiamata,
											tramite l’utilizzo della piattaforma online “Mymentis”, nelle date
											concordate tra Paziente e Professionista;
										</li>
										<li>
											la durata globale del trattamento, non essendo quantificabile a priori,
											sarà commisurata alle esigenze del trattamento stesso;
										</li>
										<li>
											la prestazione è finalizzata ad attività di prevenzione, diagnosi, cura e riabilitazione;
										</li>
										<li>
											per il conseguimento degli obiettivi potranno essere usati i seguenti strumenti:
											(i) Raccolta Anamnestica; (ii) Esame psichico; (iii) Somministrazione di test psicodiagnostici;
											(iv) Valutazione di referti di laboratorio e strumentali; (iv) Prescrizione di terapie farmacologiche;
											(v) Indicazione ad accertamenti di laboratorio e strumentali, anche per il tramite di strumenti gestionali,
											di messaggistica e videochiamata disponibili sulla piattaforma online “Mymentis”;
										</li>
										<li>
											in qualsiasi momento è possibile interrompere il rapporto comunicando alla/al Professionista la volontà di interruzione;
										</li>
										<li>
											{therapist?.gender === 'male' ? 'il' : 'la'} Professionista può valutare ed eventualmente proporre
											l’interruzione del
											rapporto quando constata che non vi sia alcun beneficio dall’intervento e non è
											ragionevolmente prevedibile che ve ne saranno dal proseguimento dello stesso;
										</li>
										<li>
											le Parti sono tenute alla scrupolosa osservanza delle date e degli orari degli appuntamenti
											che vengono concordati oppure all’inizio di ogni prestazione professionale. In caso di sopravvenuta
											impossibilità di rispettare l’appuntamento fissato, la Parte impossibilitata è tenuta a darne notizia
											all’altra in tempi congrui. Entrambe le Parti si impegnano a rendere attivi e raggiungibili i propri
											recapiti rispettivamente forniti;
										</li>
										<li>
											il compenso da corrispondere per la prestazione professionale è indicato sulla piattaforma online “Mymentis”. Si
											precisa che il compenso non può essere condizionato all’esito o ai risultati dell’intervento professionale;
										</li>
										<li>
											Le attività di trattamento dei dati personali vengono effettuate in conformità con le disposizioni del Reg.
											UE 2016/679 e del d.lgs. n. 196/2003 come meglio specificato nell’informativa privacy consegnata al Paziente.
										</li>
									</ol>
								)
							}
							{userTherapy === "single" ? (
								<p>
									Visto e compreso tutto quanto sopra indicato, {therapist?.gender === 'male' ? 'il' : 'la'} Paziente,
									avendo ricevuto apposita informativa professionale e
									informazioni adeguate in relazione a costi, fini e modalità
									della stessa dichiara e accetta di esprimere liberamente il
									proprio consenso informato per la prestazione professionale
									psicologica ivi descritta.
								</p>
							) : userTherapy === "couple" ? (
								<p>
									Visto e compreso tutto quanto sopra indicato, I Pazienti,
									avendo ricevuto apposita informativa professionale e
									informazioni adeguate in relazione a costi, fini e modalità
									della stessa dichiarano e accettano di esprimere liberamente
									il proprio consenso informato per la prestazione professionale
									psicologica ivi descritta.
								</p>
							) : userTherapy === "underage" ? (
									<p>
										Visto e compreso tutto quanto sopra indicato, {therapist?.gender === 'male' ? 'il' : 'la'}
										Paziente, avendo ricevuto apposita informativa professionale
										e informazioni adeguate in relazione a costi, fini e
										modalità della stessa dichiara e accetta di esprimere
										liberamente il proprio consenso informato, nell’esercizio
										della responsabilità genitoriale sulla/sul minore, per la
										prestazione professionale psicologica ivi descritta.
									</p>
								) : (userTherapy === "psychiatry") && (
									<p>
										Visto e compreso tutto quanto sopra indicato, {therapist?.gender === 'male' ? 'il' : 'la'} Paziente,
										avendo ricevuto apposita informativa professionale e
										informazioni adeguate in relazione a costi, fini e modalità
										della stessa di esprimere liberamente il proprio consenso
										informato per la prestazione professionale ivi descritta.
									</p>
								)
							}
						</div>
						{!informedConsetIsCompiled && (
							<Input
								value={agreeConsent}
								onChange={(e) => setAgreeConsent(e.target.checked)}
								className={styles.consentCheckbox}
								type="checkbox"
								checkboxId="Dichiaro di aver letto e compreso"
								checkboxLabel={
									<span>
                    Dichiaro di aver letto e compreso l'
                    <span
											style={{cursor: "pointer", textDecoration: "underline"}}
											onClick={() => window.open(
												`${userTherapy !== "psychiatry" ? 'https://mymentis.it/privacy-paziente-terapeuta/' : 'https://mymentis.it/privacy-policy-paziente-psc-ci/' }`,
												"_blank")}
										>
                      informativa privacy
                    </span>{" "}
										e di accettare il testo del consenso informato
                  </span>
								}
							/>
						)}
						{!informedConsetIsCompiled && (
							<div className={styles.btnWrapper}>
								<Button
									onClick={() => setSignatureModalIsOpen(false)}
									variant="secondary"
								>
									Annulla
								</Button>
								<Button
									disabled={
										!agreeConsent || submitInformedConsent.status === "loading"
									}
									onClick={() => onSubmitInformedConsent()}
								>
									Firma
								</Button>
							</div>
						)}
					</div>
				</Modal>
			)}
			{(showCurrentTherapistModal && therapist) && (
				<ModalCurrentTherapist
					therapist={therapist}
					modalClose={() => setShowCurrentTherapistModal(false)}
					goToFeedbackModal={() => setShowFeedbackModal(true)}
				/>
			)}
			{changePreferencesModalIsOpen && (
				<Modal close={() => {
					setChangePreferencesModalIsOpen(false);
				}}>
					<div className={styles.changePreferences} onClick={e => {
						e.stopPropagation()
					}}>
						<FormHeader
							closeModal={() => setChangePreferencesModalIsOpen(false)}
							title={`Sei sicur${
								userGender === "male"
									? "o"
									: userGender === "female"
										? "a"
										: "*"
							} di voler cambiare le tue preferenze?`}
							closeButton
						/>
						<div className={styles.descritpion}>
							Ti consigliamo di parlarne prima con il tuo terapeuta per capire
							se effettivamente il tuo percorso non soddisfa le tue aspettative
							e quindi provare a vedere se un altra scelta possa fare al caso
							tuo. Motiva la scelta qui sotto:
						</div>
						<Input
							textarea
							value={changeMotive}
							onChange={(e) => setChangeMotive(e.target.value)}
						/>
						<div className={styles.btnWrapper}>
							<Button
								variant="secondary"
								onClick={() => setChangePreferencesModalIsOpen(false)}
							>
								No, ci penso su
							</Button>
							<Button
								disabled={changeMotive.trim() === ""}
								onClick={() => onSendChangeTherapistFeedback("preferences")}
							>
								Sì, mostrami altri terapeuti
							</Button>
						</div>
					</div>
				</Modal>
			)}
			<h1 className={styles.title}>Gestione preferenze</h1>
			<div className={styles.preferencesContainer}>
				<div className={styles.prefernce}>
					<div className={styles.preferenceTitle}>Consenso informato</div>
					{informedConsetIsCompiled ? (
						<div className={styles.preferenceDescription}>
							Hai compilato correttamente il Consenso Informato.{" "}
							{isInformedConsentLoading || userInfo.isLoading ? (
								""
							) : (userTherapy === "underage") &&
							!informedConsentResponse?.otherInformedConsentParent ? (
								<>
                  <span>
                    Per poter partecipare alle sedute il consenso deve essere
                    firmato anche dall'altro genitore, tramite il link ricevuto
                    sulla sua casella postale. Clicca il bottone sottostante per
                    inviare di nuovo il link.
                  </span>
									{!userInfo?.data?.user?.parentEmail && (
										<>
											<br/>
											<span style={{marginTop: 20}}>
                        Il pulsante "invia consenso informato al genitore" non
                        risulta cliccabile perchè l'email del genitore non è
                        stata correttamente inserita. Vai nella sezione{" "}
												<span
													onClick={() => {
														navigate(routes.ProfileSection.links.info);
													}}
													style={{
														cursor: "pointer",
														textDecoration: "underline",
													}}
												>
                          informazioni personali
                        </span>{" "}
												e modificala.
                      </span>
										</>
									)}
								</>
							) : (
								<>
									Se in futuro vorrai modificare il consenso potrai farlo
									contattando il Supporto. Puoi visualizzare l’Informativa
									Privacy al{" "}
									<span
										className={styles.underlinedText}
										onClick={() => {
											window.open(`${userTherapy !== "psychiatry" ? 'https://mymentis.it/privacy-paziente-terapeuta/' : 'https://mymentis.it/privacy-policy-paziente-psc-ci/' }`)
										}}
									>
                    seguente link.
                  </span>
									{(userInfo?.data && userTherapy === "couple") &&
										!userInfo.data.user.partnerEmail && (
											<>
												<br/>
												<span style={{marginTop: 20}}>
                          Il pulsante "invia consenso informato al tuo partner"
                          non risulta cliccabile perchè l'email del partner non
                          è stata correttamente inserita. Vai nella sezione{" "}
													<span
														onClick={() => {
															navigate(routes.ProfileSection.links.info);
														}}
														style={{
															cursor: "pointer",
															textDecoration: "underline",
														}}
													>
                            informazioni personali
                          </span>{" "}
													e modificala.
                        </span>
											</>
										)}
								</>
							)}
						</div>
					) : (
						<div className={styles.preferenceDescription}>
							Per iniziare le {userTherapy !== "psychiatry" ? 'sedute' : 'visite'} è necessario fornire il consenso tramite la
							compilazione del form che apparirà cliccando sul pulsante in
							basso. Se in futuro vorrai modificare il consenso potrai farlo
							contattando il Supporto. Puoi visualizzare l’Informativa Privacy
							al{" "}
							<span
								className={styles.underlinedText}
								onClick={() => {
									window.open(`${userTherapy !== "psychiatry" ? 'https://mymentis.it/privacy-paziente-terapeuta/' : 'https://mymentis.it/privacy-policy-paziente-psc-ci/' }`)
								}}
							>
                seguente link
              </span>
							.
						</div>
					)}
				</div>
				{!informedConsetIsCompiled && (
					<InfoBox
						className={styles.consensInfo}
						text="Il consenso informato ha lo scopo di informare la persona assistita riguardo le modalità e le finalità delle prestazioni che riceverà, promuovendo l'autonomia dell'individuo sulle scelte riguardanti il proprio benessere psico-fisico (art. 24 del codice deontologico)."
					/>
				)}
				<div className={styles.btnWrapper}>
					{informedConsetIsCompiled ? (
						<Button
							onClick={() => {
								setSignatureModalIsOpen(true);
							}}
							disabled={
								(userTherapy === "underage" &&
									!informedConsentResponse?.otherInformedConsentParent) ||
								(userTherapy === "couple" &&
									!informedConsentResponse?.otherInformedConsent)
							}
						>
							Visualizza il consenso informato
						</Button>
					) : (
						<Button
							onClick={() => {
								setInformedConsentModalIsOpen(true);
							}}
						>
							Compila il consenso informato
						</Button>
					)}
					{((informedConsentResponse?.otherInformedConsent === null &&
							userTherapy === 'couple') ||
						(informedConsentResponse?.otherInformedConsentParent === null &&
							userTherapy === 'underage')) && (
						<Button
							onClick={async () => {
								await sendInformedConsent.mutateAsync({
									therapistId: therapistData?.therapistId,
									accountTherapyType: therapistData?.therapyType
								}).catch((e) => {
									raiseException(e);
									dispatch(
										setInfo({
											variant: "error",
											text: "Prova a ricaricare la pagina e verifica di essere connsso a internet",
											title: "Si è verificato un errore",
										})
									);
								});
								dispatch(
									setInfo({
										variant: "confirm",
										text: "Email inviata con successo",
										title:
											userTherapy === 'couple'
												? "Il consenso informato è stato inviato con successo al tuo partner"
												: "Il consenso informato è stato inviato con successo al genitore",
									})
								);
							}}
							disabled={
								(userTherapy === 'couple' &&
									!userInfo.data?.user?.partnerEmail) ||
								(userTherapy === 'underage' &&
									!userInfo.data?.user?.parentEmail)
							}
						>
							{userTherapy === 'couple'
								? "Invia consenso informato al tuo partner"
								: "Invia consenso informato al genitore"}
						</Button>
					)}
				</div>
			</div>
			<div className={styles.preferencesContainer}>
				<div className={styles.prefernce}>
					<div className={styles.preferenceTitle}>
						Il tuo {userTherapy !== "psychiatry" ? 'terapeuta' : 'psichiatra'} non ti convince?
					</div>
					<div className={styles.preferenceDescription}>
						Ripeti il questionario per modificare le tue preferenze e poter
						scegliere un nuovo professionista oppure scegli direttamente un
						nuovo {userTherapy !== 'psychiatry' ? 'terapeuta' : 'psichiatra'} senza cambiare le
						preferenze del questionario.
					</div>
				</div>
				<div className={styles.btnWrapper}>
					{(therapistData?.main ? !userInfo.data?.user.changeTherapist : !therapistData?.changeTherapist) && (
						<Button
							onClick={() => {
								setShowCurrentTherapistModal(true);
							}}
						>
							Scegli un nuovo {userTherapy !== "psychiatry" ? 'terapeuta' : 'psichiatra'}
						</Button>
					)}
				</div>
				{(therapistData?.main ? userInfo.data?.user.changeTherapist : therapistData?.changeTherapist) && (
					<div className={styles.prefernce}>
						<div className={styles.preferenceDescription}>
							Hai già eseguito un cambio {userTherapy !== "psychiatry" ? 'terapeuta' : 'psichiatra'}, al momento non è possibile farne altri.
							Scrivi a <a href="mailto: clientsupport@mymentis.it">clientsupport@mymentis.it</a> se hai bisogno di
							assistenza.
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
