import Button from "components/atoms/Button";
import Tabs from "components/atoms/Tabs";
import { useEffect, useState } from "react";
import { trpc } from "utils/trpc";
import crossedCalendar from "../../../theme/svg/crossed-calendar.svg";
import styles from "./PatientDetail.module.scss";
import AppointmentCard from "components/molecules/AppointmentCard";
import { InfoCircleOutlined } from "@ant-design/icons";
import Modal from "../Modal";
import { getNormalizedDate } from "../../../utils/dateTime";
import MedicalDocuments from "../MedicalDocuments";

interface Props {
  patient?: any;
  myTherapist?: any;
  userInfo?: any;
  showDocuments?: boolean,
  showSurveyAnswers?: boolean,
  onGoToChat?: any,
  chat: any,
  getinformedConsentResponse: any
}

export const PatientDetail = ({
  patient,
  myTherapist,
  showDocuments,
  showSurveyAnswers,
  onGoToChat,
  chat,
  getinformedConsentResponse
}: Props) => {

  const [appointments, setAppointments] = useState<any[]>([]);
  const [expiredAppointments, setExpiredAppointments] = useState<any[]>([]);

  const emptyState = (
    <div className={styles.emptyState}>
      <div className={styles.iconWrapper}>
        <img src={crossedCalendar} alt="crossed calendar" />
      </div>
      <div>
        Non hai appuntamenti in programma.
      </div>
      <Button
        className={styles.emptyStateBtn}
        onClick={() => onGoToChat()}
        variant="secondary"
      >
        Vai alla chat
      </Button>
    </div>
  );

  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {patientId: patient?.id}
  ).data;

  useEffect(() => {
    if (getAppointments) {
      setAppointments(
        getAppointments?.appointments.filter(
          (a) => a.status === "ready" || a.status === "waiting_for_confirm" || a.status === "payed_to_be_confirmed"
        )
      );
      setExpiredAppointments(
        getAppointments.appointments
          .filter((a) => a.status !== "ready")
          .filter((a) => a.status !== "waiting_for_confirm")
          .filter((a) => a.status !== "payed_to_be_confirmed")
      );
    }
  }, [getAppointments]);

  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [modalChildren, setModalChildren] = useState<any>(null);

  const tabsItems = [
    {
      key: 1,
      label: "Prossime sedute",
      active: true,
      content: (
        <div className={styles.bottomPart}>
          {appointments.length > 0 ? (
            <div style={{ width: "100%" }}>
              <div className={styles.programmedAppointment}>
                {appointments.map((i, index) => {
                  return (
                    <div key={index}>
                      <AppointmentCard
                        appointment={i}
                        role={"therapist"}
                        isPsychiatry={patient?.therapyType === 'psychiatry'}
                        setModalChildren={setModalChildren}
                        setModalIsVisible={setModalIsVisible}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className={styles.emptyStateContainer}>{emptyState}</div>
          )}
        </div>
      ),
    },
    {
      key: 2,
      label: "Sedute passate",
      //   active: openMainTab === "past",
      content: (
        <div className={styles.bottomPart}>
          {expiredAppointments.length > 0 ? (
            <div className={styles.programmedAppointment}>
              {expiredAppointments.map((a, index) => {
                const appointmentDate = getNormalizedDate(
                  a.startTime
                ).toLocaleDateString("it", {
                  weekday: "short",
                  day: "2-digit",
                  month: "long",
                });

                const appointmentHour = `${getNormalizedDate(
                  a.startTime
                ).toLocaleTimeString("it", {
                  hour: "2-digit",
                  minute: "2-digit",
                })} - ${getNormalizedDate(a.endTime).toLocaleTimeString("it", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`;

                let appointmentStatus;

                switch (a.status) {
                  case "expired":
                    appointmentStatus = "Cancellato";
                    break;
                  case "cancelled":
                    appointmentStatus = "Cancellato";
                    break;
                  case "completed":
                    appointmentStatus = "Completato";
                    break;
                  case "moved":
                    appointmentStatus = "Spostato";
                    break;
                  case 'payed_moved':
                    appointmentStatus = 'payed_moved (!!!)';
                    break;
                  default:
                    appointmentStatus = a.status;
                }

                return (
                  <div key={index} className={styles.pastAppointmentTherapist}>
                    <div className={styles[a.status]}>
                      <InfoCircleOutlined /> {appointmentStatus}
                    </div>
                    <div className={styles.patientName}>
                      {a?.patient?.given_name} {a?.patient?.family_name}{" "}
                      {!a.patient && "Paziente Eliminato"}
                    </div>
                    <div className={styles.appointmentDetail}>
                      <div>
                        {appointmentDate} | {appointmentHour}
                      </div>

                      <div className={styles[a.status]}>
                        <InfoCircleOutlined /> {appointmentStatus}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles.emptyStateContainer}>{emptyState}</div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.patientDetail}>
      {modalIsVisible && <Modal close={() => {
        setModalIsVisible(false);
      }}>{modalChildren}</Modal>}
      {!showSurveyAnswers && !showDocuments && !showSurveyAnswers && (
        <div className={styles.patientAppointment}>
          <Tabs
            labelClassName={styles.tabsLabel}
            className={styles.tabs}
            items={tabsItems}
          />
        </div>
      )}
      {showDocuments && (
        <MedicalDocuments
          myTherapist={myTherapist}
          patient={patient}
          chat={chat}
          getinformedConsentResponse={getinformedConsentResponse}
        />
      )}
    </div>
  );
};
