import styles from './PatientBookingFirstAppointment.module.scss';
import { useEffect, useState } from 'react';
import { trpc } from '../../utils/trpc';
import { envs } from '../../utils/envs';
import Avatar from '../../components/atoms/Avatar';
import { useLocation, useNavigate, useRouteLoaderData } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import Modal from '../../components/organisms/Modal';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import card from '../../theme/images/card.svg'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowUturnLeftIcon
} from '@heroicons/react/20/solid';
import Button from '../../components/atoms/Button';
import routes from '../../routes';
import Emitter from '../../utils/eventemitter';
import { getAvailableDates } from '../../utils/avaliableDate';
import TherapistFeedbackModal from '../../components/organisms/TherapistFeedbackModal';
import ModalCurrentTherapist from '../../components/organisms/ModalCurrentTherapist';
import { checkIf48HoursPassed } from '../../utils/checkIf48HoursPassed';
import { setInfo } from '../../store/slices/infoSlice';
import { useAppDispatch } from '../../customHooks/reduxHooks';
import { raiseException } from '../../utils/raiseException';
import { checkCompletedAppointmentExist, firstAppointmentExist } from '../../utils/checkAppointmentExist';
import { getNormalizedDate, getNormalizedTime, currentUserLocationIsItaly } from '../../utils/dateTime';
import PatientPaymentForm from '../../components/PatientPaymentForm';
import FormHeader from '../../components/atoms/FormHeader';
import PatientPayment from '../../components/organisms/PatientPayment';
import { getParsedCPS } from '../../utils/getParsedCPS';
import { useTherapistData } from '../../customHooks/therapistDataContext';

export const PatientBookingFirstAppointment = ({className}) => {
  const socket = useRouteLoaderData("firstAppointmentRoot") as Socket;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedChat, setSelectedChat] = useState<string | undefined>('');
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);
  const [therapistsToggle, setTherapistToggle] = useState(true);
  const [therapistId, setTherapistId] = useState<any>('');
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [isFirstAppointment, setIsFirstAppointment] = useState<boolean>(false);
  const [completedAppointment, setIsCompletedAppointment] = useState<boolean>(false);
  const [specToShow, setSpecToShow] = useState(3);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBillingModal, setIsBillingModal] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showCurrentTherapistModal, setShowCurrentTherapistModal] =
    useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [modalChildren, setModalChildren] = useState<JSX.Element | undefined>();
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const {therapistData} = useTherapistData();

  const createAppointment =
    trpc.mainService.appointment.createAppointment.useMutation();

  const createWidgetMessage =
    trpc.mainService.appointment.createWidgetMessage.useMutation();

  const deleteAppointment = trpc.mainService.appointment.deleteAppointment.useMutation();

  const userInfo = trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined});

  const myTherapist = trpc.mainService.patient.getMyTherapist.useQuery(
    {therapistId: therapistData?.therapistId || undefined},
    {
      enabled: userInfo?.data?.user.role === 'patient',
    }
  ).data?.therapist;

  const getMyTherapistFreeDates = trpc.mainService.patient.getTherapistAvailability.useQuery(
    {therapistId: therapistData?.therapistId || myTherapist?.id, patientId: userInfo?.data?.user?.id},
    {enabled: userInfo?.data?.user.role === 'patient' && !!myTherapist?.id}
  );

  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {patientId: userInfo?.data?.user.id},
    {enabled: userInfo?.data?.user.role === 'patient'}
  )

  const getBillingDetailsResponse =
    trpc.mainService.patient.getBillingDetails.useQuery(undefined, {
      enabled: loading && userInfo?.data?.user.role === 'patient'
    })

  const getPaymentIntent =
    trpc.mainService.payments.stripe.getPaymentIntent.useMutation();

  const isPsychiatry = (therapistData?.therapyType ?? userInfo?.data?.user?.therapyType) === 'psychiatry';
  const isTherapistPsychiatry = therapistData?.therapyType === 'psychiatry' || myTherapist?.therapyType.includes('psychiatry');

  useEffect(() => {
    if (!isAllFieldsFilled && !isBillingModal && isPsychiatry && !therapistsToggle) {
      setIsAllFieldsFilled(userInfo?.data?.user.role === 'patient' && areAllFieldsFilled(getBillingDetailsResponse?.data?.billingDetails))
    }
  }, [isAllFieldsFilled, therapistsToggle]);


  function areAllFieldsFilled(obj: any) {
    if (!isPsychiatry) {
      return true;
    }
    if (obj && Object.keys(obj).length > 0) {
      return Object.values(obj).every(value => Boolean(value));
    }
    return false;
  }

  const interventionAreas = myTherapist?.specializations || [];

  const handleBillingSubmit = async () => {
    const result = await getBillingDetailsResponse.refetch()
    if (result.status === "success") {
      const allFieldsFilled = areAllFieldsFilled(result.data.billingDetails)
      setIsAllFieldsFilled(allFieldsFilled)
      if (allFieldsFilled) {
        await createFirstAppointment(allFieldsFilled).then(() => setIsBillingModal(false))
      }
    }
  }

  const createFirstAppointment = async (data: boolean) => {
    if (therapistId && selectedDateTime && (isPsychiatry ? data : true)) {
      setIsBillingModal(false)
      const date = getNormalizedTime(new Date(selectedDateTime));
      try {
        const chatObject: any = userInfo?.data?.user?.chats?.find((item) => item.therapistId === therapistId);
        const type = therapistData?.therapyType || chatObject?.therapyType;
        const {appointment} = await createAppointment.mutateAsync({
          startTime: date,
          patientId: userInfo?.data?.user?.id!,
          therapistId: therapistId,
          accountTherapyType: type,
          createdByPatient: true
        });
        if (selectedChat && appointment?.id) {
          try {
            const widget: any = await createWidgetMessage.mutateAsync({
              chatId: selectedChat,
              type: "widget",
              appointmentId: appointment?.id
            });

            if (widget?.appointmentData?.id && isPsychiatry) {
              getAppointments.refetch()
              getMyTherapistFreeDates.refetch().then(() => {
                setSelectedDateTime(null)
                setTherapistToggle(true)
              })
              await onPatientPayment(widget?.appointmentData, widget?.newMessage.id)
            }

            if (widget?.appointmentData?.id) {
              socket.emit(
                "sendWidgetMessage",
                widget?.newMessage,
                widget?.appointmentData,
                widget?.chat
              );
              if (!isPsychiatry) {
                navigate(routes.Chat.path)
              }
            }
          } catch (error) {
            try {
              await deleteAppointment.mutateAsync({appointmentId: appointment?.id});
            } catch {}
              dispatch(
                setInfo({
                  text: "Si è verificato un errore, prova a creare nuovamente l'appuntamento.",
                  variant: "error",
                })
              );
          }
        }
      } catch (error: any) {
        raiseException(error);
        if (error?.shape?.errorCode === "AppointmentInvalidStartTime") {
          dispatch(
            setInfo({
              text: "L'ora di inizio selezionata è troppo vicina",
              variant: "error",
            })
          );
        } else {
          dispatch(setInfo({ text: "Errore inaspettato", variant: "error" }));
        }
      }
    } else {
      setIsBillingModal(true)
    }
  }

  useEffect(() => {
    Emitter.on("updateTherapistChange", () => setTherapistToggle(true));
  }, []);

  useEffect(() => {
    if (therapistsToggle && (therapistData || myTherapist?.id)) {
      if (userInfo.status === "loading" || getAppointments.status === 'loading') {
        setLoadingToggle(true);
        setLoading(true)
        setShowFeedbackModal(false)
        if (userInfo?.data?.user?.role && userInfo?.data?.user?.role !== 'patient') {
          navigate(routes.Chat.path);
          return;
        }
      } else if (
        !userInfo.isFetching &&
        userInfo.status === "success" &&
        getMyTherapistFreeDates.status === "success" &&
        getBillingDetailsResponse.status === "success" &&
        myTherapist?.id && getAppointments.status === "success"
      ) {
        const therapistId = therapistData?.therapistId || myTherapist?.id;
        const chatObject: any = userInfo?.data?.user?.chats?.find((item) => item.therapistId === therapistId);
        const chat = therapistData?.chat?.id || chatObject?.id;
        const result = checkCompletedAppointmentExist(getAppointments?.data?.appointments, therapistId!);
        setIsCompletedAppointment(result);
        setLoadingToggle(false);
        setTherapistToggle(false);
        const therapistFreeDates: any = getMyTherapistFreeDates.data;
        setAvailableDates(
          getAvailableDates(
            therapistFreeDates?.daySlots,
            therapistFreeDates?.timeSlots,
            therapistFreeDates?.appointmentsBusyTime,
            therapistFreeDates?.offlineAppointments
          ))
        setTherapistId(therapistId)
        setSelectedChat(chat)
        setLoading(false)
      }
    }
  }, [
    therapistsToggle,
    userInfo.isFetching,
    getMyTherapistFreeDates.status,
    getBillingDetailsResponse.status,
    myTherapist,
    getAppointments.status
  ]);

  const handleDateClick = (day: string, time: string, available: any) => {
    if (!available) return;
    setSelectedDateTime(`${day} ${time}:00`);
  };

  const closeModal = () => {
    setModalIsVisible(false);
  };

  const onPatientPayment = async (appointment: any, messageId: number) => {
    const onPayAppointment = async () => {
      socket.emit("updateWidgetMessage", messageId, selectedChat);
    };
    const response = await getPaymentIntent.mutateAsync({
      appointmentId: appointment.id,
    });

    setModalChildren(
      <PatientPayment
        isModal
        className="modalWithSmallHeader"
        closeModal={closeModal}
        userInfo={userInfo?.data?.user}
        myTherapist={myTherapist}
        savedPayment={
          userInfo?.data?.user?.stripeDefaultPaymentMethodId ??
          undefined
        }
        setupFutureUsage={Boolean(response.setupFutureUsage)}
        onPayAppointment={onPayAppointment}
        paymentIntentId={response.paymentIntentId}
        clientSecret={response.clientSecret!}
        amount={response.amount / 100}
        appointment={appointment}
      />
    )
    setModalIsVisible(true);
  }

  const changeTherapistButton = () => {
    if (!therapistData || therapistData?.main) {
      return !userInfo?.data?.user?.changeTherapist && completedAppointment;
    }

    return !therapistData?.changeTherapist && completedAppointment;
  };

  return (
    <div className={`${styles.firstAppointmentBookingContainer} ${className}`}>
      {isBillingModal && isPsychiatry && !modalIsVisible && (
        <Modal modal className="modalWithSmallHeader" subClassName="modalWithSmallHeader" close={() => {
          setIsBillingModal(false);
        }}>
          <FormHeader
            title="Dati di fatturazione"
            closeModal={() => {
              setIsBillingModal(false);
            }}
            closeButton
          />
          <PatientPaymentForm
            getBillingDetailsResponse={getBillingDetailsResponse}
            isModal={false}
            userInfo={userInfo?.data?.user}
            setLoadingModalIsOpen={handleBillingSubmit}
            closeModal={setIsBillingModal}
          />
        </Modal>
      )}
      {modalIsVisible && !isBillingModal && isPsychiatry &&
        <Modal className="modalWithSmallHeader">{modalChildren}</Modal>}
      {loading ? <Modal>
          <div className={styles.loadingSpinner}>
            <Spin
              className={styles.spinner}
              indicator={
                <LoadingOutlined
                  color={"#3576bb"}
                  style={{fontSize: 32}}
                  spin
                />
              }
            />
          </div>
        </Modal> :
        <div className={styles.appointmentBooking}>
          <div className={styles.appointmentContainer}>
            <div className={styles.appointmentTitle}>
              <p>{isPsychiatry ? 'Prenota il tuo appuntamento' : 'Prenota il tuo appuntamento gratuito'}</p>
            </div>
            <Button
              leftIcon={<ArrowUturnLeftIcon/>}
              className="small"
              variant="secondary"
              onClick={async () => {
                navigate(routes.Chat.path);
              }}
            >
              Vai alla chat
            </Button>
            <div className={styles.appointmentHeader}>
              Seleziona giorno e orario che preferisci:
              {!currentUserLocationIsItaly() &&
                <p>Ricorda che l'orario, in ogni nostra comunicazione, si riferisce al fuso orario italiano</p>}
            </div>
            <div className={styles.appointmentBlock}>
              {availableDates.map((appointment: any, index: number) => (
                <div key={index} className={styles.dateCard}>
                  <div className={styles.dateCardTitle}>
                    {new Date(appointment.day).toLocaleDateString('it-IT', {weekday: 'long'})}
                    <span>
                      <div>
                        {new Date(appointment.day).toLocaleDateString('it-IT', {
                           day: '2-digit',
                           month: 'long'
                        })}
                      </div>
                    </span>
                  </div>
                  <div className={styles.slotsContainer}>
                    {appointment.availableTime.map((availableTime: {
                      time: string,
                      available: boolean,
                      offline: boolean
                    }, timeIndex: string) => {
                      return (
                        <div
                          key={timeIndex}
                          className={`${styles.slot} ${selectedDateTime === `${appointment.day} ${availableTime.time}:00` ? styles.selected : ''} ${!availableTime.available ? styles.disabled : ''}`}
                          onClick={() => handleDateClick(appointment.day, availableTime.time, availableTime.available)}
                        >
                          {availableTime.time}
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
            </div>
            <Button className={styles.appointmentBlockButton} variant="primary" onClick={async () => {
              navigate(routes.Chat.path);
            }}>Vai alla chat</Button>
          </div>
          <div className={styles.therapistContainer}>
            <div className={`${styles.therapistInfoContainer} ${isMenuOpen ? styles.visible : ''}`}>
              <div className={styles.therapistWrapper}>
                <div className={styles.therapistInfo}>
                  <div className={styles.therapistName}>{myTherapist?.given_name} {myTherapist?.family_name}
                  </div>
                  <span className={styles.therapistTitle}>{myTherapist?.title}</span>
                  <div className={`${styles.therapistInfoCard} ${isPsychiatry && styles.isPsychiatry}`}>
                    <img src={card} alt="card"/>
                    <div className={styles.aboutDoc}>
                      <div className={styles.aboutTitle}>
                        Iscritt{myTherapist?.gender === 'male' ? 'o' : 'a'} {isTherapistPsychiatry ? 'all’Ordine dei Medici' : `all'albo degli
                        psicologi`}
                      </div>
                      <div className={styles.aboutText}>
                        Regione {myTherapist?.licenceRegisterPlace}
                      </div>
                    </div>
                  </div>
                  {/*<div className={styles.experienceWrapper}>*/}
                  {/*  <img src={verify} alt="verify"/>*/}
                  {/*  <span>6</span>{' '} anni di esperienza*/}
                  {/*</div>*/}
                </div>
                <Avatar className={styles.profilePicture}
                        avatarImg={`${envs.REACT_APP_S3_ENDPOINT}/pic-${myTherapist?.id}?t=${Date.now()}`}/>
              </div>

              <div className={`${styles.infoBlock} ${isMenuOpen ? styles.visible : ''}`}>
                <div className={styles.graduate}>
                  <div className={styles.graduateTitle}>
                    Laureat{myTherapist?.gender === "male" ? "o" : "a"} in
                  </div>
                  <div className={styles.aboutText}>
                    {myTherapist?.graduationCourse} presso{" "}
                    {myTherapist?.graduationPlace}
                  </div>
                </div>
                <div className={styles.interventionArea}>
                  <div className={styles.title}>Aree d'intervento</div>
                  <div className={styles.areas}>
                    {interventionAreas.length &&
                      getParsedCPS(isTherapistPsychiatry, interventionAreas)?.map(
                        (interventionArea, index: number) => {
                          if (index < specToShow)
                            return (
                              <Button
                                key={index}
                                variant="secondary"
                                className={styles.areasBtn}
                              >
                                {interventionArea}
                              </Button>
                            );
                        }
                      )}
                    {getParsedCPS(isTherapistPsychiatry, interventionAreas).length > 3 && specToShow === 3 && (
                      <Button
                        onClick={() => setSpecToShow(getParsedCPS(isTherapistPsychiatry, interventionAreas).length)}
                        variant="secondary"
                        className={styles.areasBtn}
                      >
                        + {interventionAreas && getParsedCPS(isTherapistPsychiatry, interventionAreas).length - 3}
                      </Button>
                    )}
                    {getParsedCPS(isTherapistPsychiatry, interventionAreas).length > 3 && specToShow > 3 && (
                      <Button
                        variant="secondary"
                        className={styles.areasBtn}
                        onClick={() => setSpecToShow(3)}
                      >
                        -
                      </Button>
                    )}
                  </div>
                  {(changeTherapistButton()) && (
                    <Button
                      className={styles.changeTherapistButton}
                      variant="secondary"
                      onClick={() => setShowCurrentTherapistModal(true)}
                    >
                      {isPsychiatry ? 'Cambia psichiatra' : 'Cambia terapeuta'}
                    </Button>
                  )}
                </div>
              </div>
              {showFeedbackModal && (
                <TherapistFeedbackModal
                  className="modalWithSmallHeader"
                  myTherapist={myTherapist}
                  userInfo={userInfo?.data?.user}
                  modalClose={() => setShowFeedbackModal(false)}
                />
              )}
              {(showCurrentTherapistModal && myTherapist) &&
                <ModalCurrentTherapist
                  className="modalWithSmallHeader"
                  therapist={myTherapist}
                  modalClose={() => setShowCurrentTherapistModal(false)}
                  goToFeedbackModal={() => setShowFeedbackModal(true)}
                />
              }
              <div className={styles.infoBlockButton} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <span>Mostra la scheda</span>
                {!isMenuOpen ? <ChevronDownIcon height={25}/> : <ChevronUpIcon height={25}/>}
              </div>
            </div>
            {selectedDateTime && (
              <div className={styles.appointmentWrapper}>
                <div className={styles.appointmentDateWrapper}>
                  <div className={styles.appointmentDate}>
                    {getNormalizedDate(selectedDateTime).toLocaleDateString('it-IT', {weekday: 'long'})}
                    <span>
                   <div>{getNormalizedDate(selectedDateTime).toLocaleDateString('it-IT', {
                     day: '2-digit',
                     month: 'long'
                   })}
                     </div>
                      {new Date(selectedDateTime).toLocaleTimeString('it-IT', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                  </span>
                    {'-'}
                  </div>
                  <div className={styles.appointmentName}>
                    {myTherapist?.given_name} {myTherapist?.family_name}
                  </div>
                </div>
                <Button className={styles.appointmentButton} variant='primary'
                        onClick={async () => createFirstAppointment(isAllFieldsFilled)}>
                  Conferma
                </Button>
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
};
