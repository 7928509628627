import {LoadingOutlined} from "@ant-design/icons";
import {
	ArrowLeftIcon,
	CalendarDaysIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";
import {Collapse, Spin} from "antd";
import FormHeader from "components/atoms/FormHeader";
import FileWidget from "components/molecules/FileWidget";
import {useEffect, useRef, useState} from "react";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import routes from "routes";
import {Socket} from "socket.io-client";
import {setPatientName} from "store/slices/patientInChatSlice";
import {
	useAppDispatch,
	useAppSelector,
} from "../../../customHooks/reduxHooks";
import {Message} from "../../../pages/Chat/Chat";
import {setInfo} from "../../../store/slices/infoSlice";
import celebration from "../../../theme/svg/celebration.svg";
import {dateToShow} from "../../../utils/showDate";
import {trpc} from "../../../utils/trpc";
import Button from "../../atoms/Button";
import InfoBox from "../../atoms/InfoBox";
import Input from "../../atoms/Input";
import MessageWrapper from "../../atoms/MessageWrapper";
import AppointmentWidget from "../../molecules/AppointmentWidget";
import ModifyAppointmentForm from "../../molecules/ModifyAppointmentForm";
import PatientChatCard from "../../molecules/PatientChatCard";
import TextAreaInput from "../../molecules/TextAreaInput";
import AppointmentCalendar from "../../organisms/AppointmentCalendar";
import Modal from "../Modal";
import PatientDetail from "../PatientDetail";
import styles from "./ChatRoom.module.scss";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import * as R from "ramda";
import PatientPayment from "../PatientPayment"
import { currentUserLocationIsItaly, getNormalizedDate } from "../../../utils/dateTime";
import { envs } from "../../../utils/envs";
import {
	PaidAppointmentDisclaimerModal
} from "../../molecules/PaidAppointmentDisclaimerModal/PaidAppointmentDisclaimerModal";
import { useTherapistData } from '../../../customHooks/therapistDataContext';

interface Props {
	docSheetIsOpen: boolean;
	className?: string;
	myTherapist: any;
	user: any;
	chatId?: any;
	showDocuments: boolean;
	setShowDocuments: React.Dispatch<React.SetStateAction<boolean>>;
	showSurveyAnswers: boolean;
	setShowSurveyAnswers: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChatRoom = ({
	docSheetIsOpen,
	className = "",
	myTherapist,
	user,
	chatId,
	showDocuments,
	setShowDocuments,
	showSurveyAnswers,
	setShowSurveyAnswers
}: Props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const socket = useRouteLoaderData("root") as Socket;
	const appSiteUrl = envs.REACT_APP_SITE_URL;

	const patientName = useAppSelector((store) => store.patientInChat);
	const onboarding = useAppSelector((store) => store.onBoardingDone);

	const [stringToSearch, setStringToSearch] = useState("");
	const [searchedMessages, setSearchedMessages] = useState<any[]>([]);
	const [previousMessageToggle, setPreviousMessagesToggle] = useState(false);
	const [scrollPreviousToggle, setScrollPreviousToggle] = useState(true);
	const [selectedPatientId, setSelectedPatientId] = useState("");
	const [modalIsVisible, setModalIsVisible] = useState(false);
	const [modalChildren, setModalChildren] = useState<JSX.Element | undefined>();
	const [enableScrollToBottom, setEnableScrollToBottom] = useState(true);
	const [message, setMessage] = useState("");
	const [oldMessageDate, setOldMessageDate] = useState("");
	const [lastMessageBtnIsVisible, setLastMessageBtnIsVisible] = useState(false);
	const [oldMessageDateIsVisible, setOldMessageDateIsVisible] = useState(false);
	const [messagesList, setMessagesList] = useState<Message[]>([]);
	const [therapistName, setTherapistName] = useState({name: "", surname: ""});
	const [messagesToggle, setMessagesToggle] = useState(false);
	const [chatsToggle, setChatsToggle] = useState(true);
	const [refreshNotificationDots, setRefresNotificationDots] = useState(false);
	const [cursor, setCursor] = useState<number | null>(null);
	const [chats, setChats] = useState<any>([]);
	const [patientChat, setPatientChat] = useState<any>(null);
	const [actualChat, setActualChat] = useState<any>();
	const [patient, setPatient] = useState<any>(null);
	const [patientDetailIsOpen, setPatientDetailIsOpen] = useState(false);
	const [patientChatIsOpen, setPatientChatIsOpen] = useState(false);
	const [deletedPatient, setDeletedPatient] = useState("");
	const conversationBottomRef = useRef<null | HTMLDivElement>(null);
	const messageWrapperRef = useRef<HTMLDivElement[]>([]);
	const conversationTopRef = useRef<null | HTMLDivElement>(null);
  const [showGreyBlock, setShowGreyBlock] = useState(true);
	const [isModalPaymentOpen, setIsModalPaymentOpen] = useState(false);
	const showGreyBlockByUser = JSON.parse(localStorage.getItem('greyBlock') as any) || false;
	const {therapistData} = useTherapistData();

	useEffect(() => {
		analyticsService({'event': 'virtualPageview', 'pageUrl': '/dashboard/chat', 'pageTitle': 'Chat'});
	}, []);

	const getChats = trpc.mainService.chat.getChats.useQuery(undefined, {
		enabled: chatsToggle,
	});

	const getPatient = trpc.mainService.therapist.getPatient.useQuery(
		{
			patientId: selectedPatientId,
			accountTherapyType: patientChat?.therapyType,
			therapistId: patientChat?.therapist.id
		},
		{enabled: !!(user?.role === 'therapist' && selectedPatientId)}).data;

	useEffect(() => {
		if (user?.role === 'patient' && therapistData?.chat?.id && getChats.isSuccess) {
			const actualChat = getChats.data?.chats.find(
				(c) => c.id === therapistData?.chat?.id
			);
			actualChat && chatHandler(actualChat)
		} else if (user?.role === 'patient' && !therapistData?.chat?.id && getChats.isSuccess) {
			const actualChat = getChats.data?.chats.find(
				(c) => c.id === myTherapist?.id
			);
			actualChat && chatHandler(actualChat)
		} else if (user?.role === 'therapist' && getChats.isSuccess && chatId) {
			const isChat = getChats?.data?.chats.some((i) => i.id === chatId)
			if (isChat) {
				const actualChat = getChats.data?.chats.find(
					(c) => c.id === chatId
				)
				actualChat && chatHandler(actualChat)
			}
		}
	}, [therapistData?.chat, getChats.isSuccess]);

	const isPsychiatry: boolean = user?.role === 'therapist' ? user?.therapies?.includes('psychiatry') : (therapistData?.therapyType ?? user?.therapyType) === 'psychiatry';

	const patientStatuses = {
		new_patient: isPsychiatry ? 'Prima visita' : 'Nuovi pazienti',
		therapy: isPsychiatry ? 'Controlli' : 'In terapia',
		first_appointment: 'Primo colloquio',
		inactive: 'Inattivi',
	};

	const filterChats = (status: string) => {
		if (status === 'therapy' && isPsychiatry) {
			return (chat: any) =>
				chat.patientStatus === 'therapy' || chat.patientStatus === 'first_appointment';
		}
		return (chat: any) => chat.patientStatus === status;
	};


	const PatientChatList = ({
		chats,
		filter,
	}: {
		chats: any[];
		filter: (chat: any) => boolean;
	}) => (
		<div className={styles.patientCardsWrapper}>
			{chats.filter(filter).map((chat) => {
				const therapyType = chat?.therapyType || chat?.patient?.surveys?.type;

				const handleClick = () => {
					chatHandler(chat);
					setPatientChatIsOpen(true);
					setRefresNotificationDots(true);
					dispatch(
						setPatientName({
							name: chat?.patient?.given_name,
							surname: chat?.patient?.family_name,
							id: chat?.patient?.id,
						})
					);
				};

				return (
					<div key={chat.id || chat.patient?.id} onClick={handleClick}>
						<PatientChatCard
							isSelected={
								chat?.deletedPatientName
									? chat?.id === actualChat?.id
									: selectedPatientId === chat?.patient?.id
							}
							patientName={
								chat.patient
									? `${chat.patient.given_name} ${chat.patient?.family_name}`
									: chat?.deletedPatientName
							}
							unreadMessages={chat.unreadMessages}
							lastMessage={chat.lastMessage}
							lastAppointment={chat.lastAppointment}
							getPatient={getPatient}
							therapyType={therapyType}
						/>
					</div>
				);
			})}
		</div>
	);

	const patientListItems = Object.entries(patientStatuses).map(([status, label], index) => ({
		key: index + 1,
		label,
		children: (
			<PatientChatList
				chats={chats}
				filter={filterChats(status)}
			/>
		),
	}));


	const getMessages = trpc.mainService.chat.getChatMessages.useQuery(
		{
			chatId: patientChat?.id,
			quantity: 50,
			cursor: cursor,
		},
		{enabled: !!(messagesToggle && patientChat?.id)}
	);

	const readChatMessages = trpc.mainService.chat.readChatMessages.useMutation();

	const searchMessage = trpc.mainService.chat.searchMessages.useMutation();

	const previousMessage =
		trpc.mainService.chat.getChatPreviousMessages.useQuery(
			{
				chatId: patientChat?.id,
				cursor: cursor!,
			},
			{
				enabled: previousMessageToggle,
			}
		);

	const getinformedConsentResponse =
		trpc.mainService.patient.getinformedConsent.useQuery({
			patientId: selectedPatientId,
			accountTherapyType: patientChat?.therapyType || undefined,
	});

	const textBeforeLink = 'Ogni 15 giorni ricordati di impostare il tuo calendario per le visite da ';

	const link = (
		<span
			onClick={() => {
				navigate(routes.Appointments.path, {
					state: {
						key: '3',
					},
				});
			}}
			className={styles.chatInfoConsent}
		>
			qui
		</span>
	);

	const errorHandler = (error: any) => {
		raiseException(error);
		dispatch(
			setInfo({
				title: error.data.code,
				text: error.message,
				variant: "error",
			})
		);
	};

	const chatHandler = async (actualChat: any) => {
		socket.emit('leave', patientChat?.id);
		socket.emit("joinChat", actualChat.id);
		try {
			await readChatMessages.mutateAsync({
				chatId: actualChat.id,
			});
		} catch (e: any) {
			raiseException(e);
			console.error(e);
		}
		setChatsToggle(true);
		getChats.refetch();
		setPatientChat(actualChat);
		setCursor(null);
		setMessagesList([]);
		setMessagesToggle(true);
		setActualChat(actualChat);
		setSelectedPatientId(actualChat?.patient?.id);
		setPatientDetailIsOpen(false);
		setEnableScrollToBottom(true);
		setShowDocuments(false);
		if (actualChat?.deletedPatientName) {
			setDeletedPatient(actualChat.deletedPatientName);
		} else {
			setDeletedPatient("");
		}
	};

	const sendMessage = () => {
		if (message?.trim() !== "") {
			socket.emit(
				"sendTextMessage",
				{type: "text", content: message},
				patientChat?.id
			);
		}
		setMessage("");
	};

	const scrollToBottom = () => {
		conversationBottomRef?.current?.scrollIntoView({
			inline: "end",
			block: "end",
		});
	};

	const scrollToTop = () => {
		conversationTopRef?.current?.scrollIntoView();
	};

	const checkScrollToBottom = (e: any) => {
		e.preventDefault();
		const target = e.target as HTMLElement;
		const sh = target.scrollHeight;
		const st = Math.floor(target.scrollTop);
		const oh = target.offsetHeight;
		const difference = sh - oh - st;
		showDateFirstMessage();
		loadOldMessages();
		if (!lastMessageBtnIsVisible && difference > 500) {
			setLastMessageBtnIsVisible(true);
		}
		if (!oldMessageDateIsVisible && difference > 100) {
			setOldMessageDateIsVisible(true);
		}
		if (
			(lastMessageBtnIsVisible || oldMessageDateIsVisible) &&
			difference < 2
		) {
			setLastMessageBtnIsVisible(false);
			setOldMessageDateIsVisible(false);
		}
	};

	const showDateFirstMessage = () => {
		if (messageWrapperRef.current.length === messagesList?.length) {
			const observer = new IntersectionObserver(
				(el) => {
					if (el.length === messagesList.length) {
						const topMessage = el.filter((i) => i.isIntersecting);
						const topMessageLenght = topMessage.length - 1;
						el.map((i: any, index) => {
							const dateString = messagesList[index]?.when;
							const date = new Date(dateString);
							if (
								index !== el.length - 1 &&
								topMessage[topMessageLenght] &&
								topMessage[topMessageLenght].target !== undefined &&
								topMessage[topMessageLenght].target === i.target &&
								oldMessageDateIsVisible &&
								oldMessageDate !== dateToShow(date, "chat")
							) {
								setOldMessageDate(dateToShow(date, "chat")!);
							}
						});
					}
				},
				{threshold: [0]}
			);
			messageWrapperRef.current.map((i) => {
				if (i) {
					observer.observe(i);
				}
			});
		}
	};

	const closeModal = () => {
		setModalIsVisible(false);
	};

	const loadOldMessages = () => {
		if (scrollPreviousToggle) {
			const observer = new IntersectionObserver(
				(el) => {
					if (
						messagesList.length !== 0 &&
						messagesList.length === el.length &&
						el[el.length - 10] &&
						el[el.length - 10].isIntersecting
					) {
						setMessagesToggle(true);
					}
				},
				{threshold: [0]}
			);
			messageWrapperRef.current.map((i, index) => {
				if (i) {
					observer.observe(i);
				}
			});
			// }
		}
	};

	const setAppointment = () => {
		setModalIsVisible(true);
		setModalChildren(
			<AppointmentCalendar
				chat={actualChat}
				patientId={actualChat.patient?.id}
				subtitle="
      Ricordati di concordare in anticipo con il paziente sulla base delle
      sue disponibilità."
				closeModal={() => setModalIsVisible(false)}
			/>
		);
	};

	const goToPatientDetail = () => {
		setPatientDetailIsOpen(true);
		setShowDocuments(false);
	};

	const onModify = (appointment: any, messageId: number) => {
		const sendModifyMessage = (modifyMessage: string) => {
			if (modifyMessage?.trim() !== "") {
				socket.emit(
					"sendTextMessage",
					{type: "text", content: modifyMessage},
					patientChat?.id
				);
				setMessage("");
			}
		};
		const onCancel = () => {
			socket.emit('updateWidgetMessage', messageId, patientChat?.id);
			setMessagesList(
				messagesList.map((i) => {
					if (i.appointmentId === appointment.id) {
						i.appointment.status = "cancelled";
					}
					return i;
				})
			);
		};
		const onMove = () => {
			socket.emit('updateWidgetMessage', messageId, patientChat?.id);
			setMessagesList(
				messagesList.map((i) => {
					if (i.appointmentId === appointment.id) {
						i.appointment.status = "moved";
					}
					return i;
				})
			);
		};

		const onPatientRequestMove = () => {
			socket.emit('updateWidgetMessage', messageId, patientChat?.id);
			setMessagesList(
				messagesList.map((i) => {
					if (i.appointmentId === appointment.id) {
						i.appointment.moveRequest = true;
					}
					return i;
				})
			);
		};

		const showDisclaimer = (message: string) => {
			setModalChildren(
				<PaidAppointmentDisclaimerModal
					closeModal={closeModal}
					appointment={appointment}
					messageId={messageId}
					message={message}
					chatId={patientChat?.id}
					onModifyAppointmentInChat={onModify}
					onCancel={onCancel}
					page={'chat'}
				/>
			)
			setModalIsVisible(true);
		}

		setModalChildren(
			<ModifyAppointmentForm
				onPatientRequestMove={onPatientRequestMove}
				onCancel={onCancel}
				onMove={onMove}
				closeModal={closeModal}
				showCancelDisclaimer={showDisclaimer}
				chat={patientChat}
				sendModifyMessage={sendModifyMessage}
				setMessage={setMessage}
				patientId={actualChat?.patient?.id || user.id}
				appointment={appointment}
				isPsychiatry={isPsychiatry}
			/>
		);
		setModalIsVisible(true);
	};

	const acceptAppointment =
		trpc.mainService.appointment.acceptFreeAppointment.useMutation();

	const acceptPayedMovedAppointment = trpc.mainService.appointment.acceptPayedMovedAppointment.useMutation();

	const onAccept = (appointmentId: string, messageId: number, isPayedMoved: boolean = false) => {
		const acceptMethod = isPayedMoved ? acceptPayedMovedAppointment : acceptAppointment;

		acceptMethod
			.mutateAsync({appointmentId, accountTherapyType: therapistData?.terapyType})
			.then((data) => {
				if (data.appointment) {
					const date = getNormalizedDate(data.appointment.startTime).toLocaleDateString(
						"default",
						{
							day: "2-digit",
							month: "long",
							year: "numeric",
						}
					);
					const time = getNormalizedDate(data.appointment.startTime).toLocaleString(
						"IT",
						{
							hour: "2-digit",
							minute: "2-digit",
						}
					);
					setModalChildren(
						<>
							<FormHeader
								title="Appuntamento confermato"
								closeModal={() => {
									setModalIsVisible(false);
									setModalChildren(undefined);
								}}
								closeButton
							></FormHeader>
							Appuntamento del {date} alle ore {time} è stato confermato con
							successo
						</>
					);
					setModalIsVisible(true);
					socket.emit('updateWidgetMessage', messageId, patientChat?.id);
				}
			})
			.catch((e) => {
				errorHandler(e);
			});
	};

	const getPaymentIntent =
		trpc.mainService.payments.stripe.getPaymentIntent.useMutation();


	const onPatientPayment = async (appointment: any, messageId: number) => {
		const onPayAppointment = async () => {
			socket.emit('updateWidgetMessage', messageId, patientChat?.id);
		};
		const response = await getPaymentIntent.mutateAsync({
			appointmentId: appointment.id,
		});

		setModalChildren(
			<PatientPayment
				isModal
				className=""
				closeModal={() => setIsModalPaymentOpen(false)}
        userInfo={user}
				myTherapist={myTherapist}
				savedPayment={
          user?.stripeDefaultPaymentMethodId ??
					undefined
				}
				setupFutureUsage={Boolean(response.setupFutureUsage)}
				onPayAppointment={onPayAppointment}
				paymentIntentId={response.paymentIntentId}
				clientSecret={response.clientSecret!}
				amount={response.amount / 100}
				appointment={appointment}
			/>
		)
		setIsModalPaymentOpen(true);
	}

	const onReady = (id: string, isFirstAppointment: string) => {
		const sessionType = isFirstAppointment && !isPsychiatry ? 'free' : 'paid';
		const userRole = user.role === 'therapist' ? 'therapist' : 'patient';
		const event = `${sessionType}_session_room_${userRole}`;

		analyticsService({ event, appointmentID: id });

		window.open(`/${routes.WaitingRoom.path.split("/")[1]}/${id}`, "_blank");
	};

	useEffect(() => {
		if (!patientDetailIsOpen) {
			setShowDocuments(false)
			setShowSurveyAnswers(false)
			scrollToBottom();
		}
	}, [patientDetailIsOpen]);

	useEffect(() => {
		const onMessage = (message) => {
			if (message.chatId !== patientChat?.id) {
				return;
			}
			if (
				(user.role === "therapist" &&
					(message.from === selectedPatientId || message.from === user.id)) ||
				user.role === "patient"
			) {
				setMessagesList(R.uniq([message, ...messagesList]).sort((a, b) => b.id - a.id));
				setEnableScrollToBottom(true);
				setShowGreyBlock(false)
				localStorage.setItem('greyBlock', JSON.stringify(true));

			}
		};
		const onUpdatedWidgetMessage = (widgetMessage) => {
			if (widgetMessage.chatId !== patientChat?.id) {
				return;
			}
			messagesList.map((message) => {
				if (message.id === widgetMessage.id) {
					message.appointment.status = widgetMessage.appointment.status;
					message.appointment.moveRequest =
						widgetMessage.appointment.moveRequest;
					message.appointment.cancelledBy =
						widgetMessage.appointment.cancelledBy;
					message.appointment.createdByPatient =
						widgetMessage.appointment.createdByPatient;
				}
				return message;
			});
		};
		socket.on("message", onMessage);
		socket.on("updatedWidgetMessage", onUpdatedWidgetMessage);

		enableScrollToBottom && scrollToBottom();

		return () => {
			socket.off("message", onMessage);
			socket.off("updatedWidgetMessage", onUpdatedWidgetMessage);
		};
	}, [socket, messagesList, enableScrollToBottom, patientChat?.id]);

	useEffect(() => {
		const onVisibilityChange = async () => {
			if (document.visibilityState === "visible") {
				if (patientChat) {
					await readChatMessages.mutateAsync({
						chatId: patientChat?.id,
					});
					setChatsToggle(true);
					await getChats.refetch();
				}
			}
		};
		document.addEventListener("visibilitychange", onVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", onVisibilityChange);
		};
	}, [patientChat]);

	useEffect(() => {
		const onNotification = async (message) => {
			if (message?.chatId === patientChat?.id) {
				try {
					if (document.visibilityState === "visible") {
						await readChatMessages.mutateAsync({
							chatId: patientChat?.id,
						});
					}
				} catch (e: any) {
					raiseException(e);
					console.error(e);
				}
			}

			setChatsToggle(true);
			const fetchedChats = await getChats.refetch();

			if (message.changePatient) {
				const currentChat = fetchedChats?.data?.chats?.find(
					(chat) => chat.id === patientChat?.id
				);
				setActualChat(currentChat);
			}
		};
		socket.on("notification", onNotification);
		return () => {
			socket.off("notification", onNotification);
		};
	}, [patientChat]);

	useEffect(() => {
		const reconnectToChat = () => {
			if (patientChat) {
				socket.emit('joinChat', patientChat?.id);
			}
		};
		socket.on("connect", reconnectToChat);

		return () => {
			socket.off("connect", reconnectToChat);
		};
	}, [patientChat, socket]);

	useEffect(() => {
		if (actualChat && user.role === "therapist") {
			socket.emit('leave', patientChat?.id);
			socket.emit("joinChat", actualChat.id);
		}
	}, [socket, actualChat, patientChat]);

	useEffect(() => {
		if (messagesToggle && patientChat) {
			if (!getMessages.isFetching && getMessages.status === "success") {
				if (getMessages.data.cursor === null) {
					setMessagesToggle(false);
				} else {
					setMessagesToggle(false);
					setMessagesList(R.uniq([...messagesList, ...getMessages.data.messages]).sort((a, b) => b.id - a.id));
					if (getMessages.data.messages.length > 0) {
						setShowGreyBlock(false)
						localStorage.setItem('greyBlock', JSON.stringify(true));
					}
					setCursor(getMessages.data.cursor);
					messagesList.length >= 20 && setEnableScrollToBottom(false);
				}
			}
			if (getMessages.status === "error") {
				setMessagesToggle(false);
				dispatch(
					setInfo({text: getMessages.error.message, variant: "error"})
				);
			}
		}
	}, [getMessages, messagesToggle, patientChat]);

	const showInformModal = (appointment: any, messageId: number) => {
		setModalChildren(
			<>
				<div>
					{isPsychiatry ?
						'Non è possibile creare un’altra sessione, hai già programmato la prima sessione con questo paziente, puoi aggiornare quella.' :
						'Non è possibile creare un’altra sessione, hai già una sessione gratuita programmata con questo paziente, puoi aggiornare quella.'
					}
				</div>
				<div className={styles.firstAppointmentButtons}>
					<Button onClick={() => {
						setModalIsVisible(false);
						setModalChildren(undefined);
					}}>
						Annulla
					</Button>
					<Button onClick={() => onModify(appointment, messageId)}>Sposta</Button>
				</div>
			</>
		);
		setModalIsVisible(true);
	}

	useEffect(() => {
		if (myTherapist) {
			const {given_name, family_name} = myTherapist;
			setTherapistName({name: given_name, surname: family_name});
		}
	}, [myTherapist]);


	useEffect(() => {
		if (chatsToggle) {
			if (!getChats.isFetching && getChats.status === "success") {
				if (refreshNotificationDots) {
					setChatsToggle(false);
					setRefresNotificationDots(false);
					setChats(getChats.data.chats);
					return;
				}
				setChatsToggle(false);
				setChats(getChats.data.chats);
			}
			if (getChats.status === "error") {
				setChatsToggle(false);
				dispatch(setInfo({text: getChats.error.message, variant: "error"}));
			}
		}
	}, [getChats, chatsToggle]);

	useEffect(() => {
		if (patientName.name === "") {
			setPatientChatIsOpen(false);
		} else {
			setMessage("");
			const actualChat = getChats.data?.chats.find(
				(c) => c.patient?.id === patientName.id
			);
			actualChat && chatHandler(actualChat);
			setPatientChatIsOpen(true);
		}
	}, [patientName]);

	useEffect(() => {
		if (getPatient) {
			setPatient(getPatient.patient);
		}
	}, [getPatient]);

	useEffect(() => {
		if (previousMessage.isSuccess && previousMessageToggle) {
			setMessagesList(previousMessage.data.messages);
			setPreviousMessagesToggle(false);
			setMessagesToggle(false);
			setEnableScrollToBottom(false);
			setScrollPreviousToggle(false);
		}
	}, [previousMessage, previousMessageToggle]);

	useEffect(() => {
		if (!scrollPreviousToggle && messagesList.length > 0) {
			scrollToTop();
			setTimeout(() => {
				setScrollPreviousToggle(true);
			}, 1500);
		}
	}, [messagesList, scrollPreviousToggle]);

	const CurrentUserLocation: any = () => {

		return (
			!currentUserLocationIsItaly() &&
			<InfoBox
				className={styles.chatInfo}
				variant="warn"
				text={
					<span>Ricorda che l'orario, in ogni nostra comunicazione, si riferisce al fuso orario italiano</span>}
			/>
		)
	}

	const getText = () => {
		if (actualChat?.patient) {
			const patientName = `${actualChat.patient.given_name} ${actualChat.patient.family_name}`;
			const decisionText = isPsychiatry
				? 'ha deciso di cambiare psichiatra.'
				: 'ha deciso di cambiare terapeuta.';
			return `${patientName} ${decisionText}`;
		} else {
			return `${actualChat?.deletedPatientName} ha deciso di eliminare il suo profilo`;
		}
	};

	return (
		<div className={`${styles.chatWrapper} ${className}`}>
			{modalIsVisible && <Modal modal subClassName="modalWithSmallHeaderMobile" close={() => {
				setModalIsVisible(false);
			}}>{modalChildren}</Modal>}
			{isModalPaymentOpen && <Modal>{modalChildren}</Modal>}
			{onboarding.checkpoint && <Modal></Modal>}
			{user?.role === 'therapist' && (
				<div
					className={`${styles.patientList} ${
						patientChatIsOpen && styles.hideList
					}`}
				>
					<Input
						placeholder="Ricerca nome paziente"
						className="searchInput"
						value={stringToSearch}
						rightIcon={
							<XMarkIcon
								onClick={() => {
									setStringToSearch("");
									setSearchedMessages([]);
								}}
							/>
						}
						onChange={(e) => {
							setStringToSearch(e.target.value);
							if (e.target.value?.trim() === "") {
								setSearchedMessages([]);
							} else {
								searchMessage
									.mutateAsync({message: e.target.value})
									.then((data) => {
										setSearchedMessages(data.messages);
									})
									.catch((e) => {
										raiseException(e);
										console.error("ERROR", e?.shape?.message)
									});
							}
						}}
					/>
					{stringToSearch?.trim() !== "" ? (
						<div className={styles.searchedMessagesWrapper}>
							{searchedMessages.map((m, index) => {
								const patient =
									m.chat.patient?.given_name +
									" " +
									m.chat.patient?.family_name;
								const messageDate = m.when;
								const messageContent = m.content;
								const therapyType = m?.chat?.patient?.surveys[0].type
								return (
									<div
										key={index}
										onClick={() => {
											socket.emit('leave', patientChat?.id);
											socket.emit("joinChat", m.chat.id);
											setPatientChat(m);
											setCursor(m.id);
											setPreviousMessagesToggle(true);
											setActualChat(m.chat);
											setSelectedPatientId(m.chat?.patientId);
											setPatientDetailIsOpen(false);
											setRefresNotificationDots(true);
											setPatientChatIsOpen(true);
											dispatch(
												setPatientName({
													name: m.chat?.patient?.given_name,
													surname: m.chat?.patient?.family_name,
													id: m.chat.patient?.id,
												})
											);
										}}
									>
										<PatientChatCard
											lastMessage={{
												when: messageDate,
												content: messageContent,
											}}
											patientName={patient}
											unreadMessages={0}
											lastAppointment={null}
											getPatient={getPatient}
											therapyType={therapyType}
										/>
									</div>
								);
							})}
						</div>
					) : (
							<Collapse
								bordered={false}
								expandIconPosition={"end"}
								items={isPsychiatry ? patientListItems.filter(item => item.key !== 3) : patientListItems}
								defaultActiveKey={Array.from(
									{length: patientListItems.length},
									(_, i) => i + 1
								)}
							></Collapse>
					)}
				</div>
			)}

			{(() => {
				// getMessages

				if (
					selectedPatientId ||
					(deletedPatient && patientChat) ||
					user.role === "patient"
				) {
					return (
						<div
							className={`${styles.chat} ${
								!patientChatIsOpen &&
								user?.role === "therapist" &&
								styles.hideChat
							}`}
						>
							{messagesToggle && (
								<div
									className={`${styles.loadingChat} ${
										user.role === "patient" ? styles.loadingChatPatient : ""
									}`}
								>
									<Spin
										indicator={
											<LoadingOutlined
												style={{fontSize: 15, color: "#2C2C4B"}}
												spin
											/>
										}
									/>
								</div>
							)}
							{user?.role === "therapist" && (
								<>
									<div className={styles.headerTherapistChat}>
										<div className={styles.headerTherapistChatInfo}>
											<div className={styles.headerTherapistChatName}>
												{/*{patientDetailIsOpen && (*/}
												{/*	<ArrowLeftIcon*/}
												{/*		className={styles.arrowIcon}*/}
												{/*		onClick={() => {*/}
												{/*			if (showDocuments) {*/}
												{/*				setShowDocuments(false);*/}
												{/*			} else if (showNotes) {*/}
												{/*				setShowNotes(false);*/}
												{/*			} else if (showSurveyAnswers) {*/}
												{/*				setShowSurveyAnswers(false);*/}
												{/*			} else {*/}
												{/*				setPatientDetailIsOpen(false);*/}
												{/*			}*/}
												{/*		}}*/}
												{/*	/>*/}
												{/*)}*/}
												<span>{actualChat?.patient?.given_name}</span> <span>{actualChat?.patient?.family_name}</span>
											</div>
											{actualChat?.lastAppointment && (
												<div
													className={styles.headerTherapistChatLastAppointment}
												>
													{<CalendarDaysIcon className={styles.icon}/>}
													<span>
                            ULTIMO APPUNTAMENTO{" "}
														{dateToShow(
															new Date(actualChat?.lastAppointment?.startTime),
															"card"
														)}
                          </span>
												</div>
											)}
										</div>
										<div className={styles.headerTherapistChatAction}>
											{actualChat?.active &&
												patientChat && (
													<div className={styles.headerTherapistChatButtonWrapper}>
														<Button
															className="small"
															variant={(showDocuments || docSheetIsOpen || patientDetailIsOpen) ? 'secondary' : 'primary'}
															onClick={() => {
																setShowDocuments(false);
																setPatientDetailIsOpen(false);
															}}
														>
															Chat
														</Button>
														<Button
															className="small"
															variant={patientDetailIsOpen && !showDocuments ? 'primary' : 'secondary'}
															onClick={() => goToPatientDetail()}
														>
															Dettagli
														</Button>
														<Button
															className="small"
															onClick={() => {
																setShowDocuments(true);
															}}
															variant={showDocuments ? 'primary' : 'secondary'}
														>
															Documenti
														</Button>
													</div>
												)}
										</div>
									</div>
									{(((patientDetailIsOpen || docSheetIsOpen) && !showDocuments)) && (
										<div className={styles.patientDataWrapper}>
											<div className={styles.patientDataContainer}>
												<div>
												<span>Genere:</span>{" "}
												{patient?.gender === "male"
													? "Uomo"
													: patient?.gender === "female"
														? "Donna"
														: "Altro"}
												</div>
												<div>
												<span>Data di registrazione:</span>{" "}
												{new Date(patient?.createdAt).toLocaleDateString("it", {
													dateStyle: "short",
												})}
												</div>
											</div>
											<Button
												onClick={() => setShowSurveyAnswers(prev => !prev)}
												variant="secondary"
											>
												Vedi risposte questionario
											</Button>
										</div>
									)}
									{!docSheetIsOpen && !showDocuments && !showSurveyAnswers && !patientDetailIsOpen && (
										<div className={styles.therapistBannerContainer}>
											<CurrentUserLocation/>
											{!patient?.informedConsent && (
												<InfoBox
													className={styles.chatInfo}
													title={"Questa è la vostra chat privata"}
													text={`
														${patient?.therapyType === "single"
															? "Ricorda al paziente di firmare il consenso informato prima di iniziare le sedute di terapia."
															: ""
														}
														${patient?.therapyType === "couple"
															? "Ricorda al paziente di firmare il consenso informato prima di iniziare le sedute di terapia. Per la coppia è necessario il consenso di entrambi i partner."
															: ""
														}
														${patient?.therapyType === "underage"
															? "Ricorda al paziente di firmare il consenso informato prima di iniziare il colloquio gratuito e le sedute di terapia. Per il minore è necessario il consenso di entrambi i genitori."
															: ""
														}
														${patient?.therapyType === "psychiatry"
															? "Ricorda al paziente di firmare il consenso informato prima di effettuare la prima visita."
															: ""
														}`}
												/>
											)}
											<InfoBox
												className={styles.chatInfo}
												text={<>{textBeforeLink}{link}</>}
											/>
											{!actualChat?.active && (
												<InfoBox
													className={styles.chatInfo}
													title={
														actualChat?.patient
															? `${actualChat?.patient?.given_name} ${actualChat?.patient?.family_name} non è più un tuo paziente`
															: `${actualChat?.deletedPatientName} ha eliminato il suo profilo`
													}
													text={getText()}
												/>
											)}
										</div>
									)}
									{showSurveyAnswers && !showDocuments && (
										<div className={styles.surveyAnswers}>
											<h5
												className={styles.title}
												style={{
													display: "flex",
													gap: '0.625rem',
												}}
											>
												<ArrowLeftIcon
													width={20}
													onClick={() => {
														setShowSurveyAnswers(false);
													}}
													style={{
														cursor: 'pointer',
													}}
												/>{' '}
												Risposte questionario
											</h5>
											<div className={styles.answersList}>
												{patient?.survey?.questionsWithAnswers.map((a, index) => {
													const title =
														a.title?.trim() === "" &&
														a.step &&
														a.numberOfSteps &&
														a.step === a.numberOfSteps
															? patient?.survey?.questionsWithAnswers[index - 1].title
															: a.title;

													let answer: string;

													switch (a.type) {
														case "radio":
															answer = a.options[a.answer].title;
															break;
														case "checkbox":
															answer = a.answer.map((i) => (
																<div>- {a.options[i].title}</div>
															));
															break;
														default:
															answer = a.answer;
													}
													if (answer) {
														return (
															<div key={index} className={styles.answerCard}>
																<div className={styles.question}>
																	{index + 1}. {title}
																</div>
																<div className={styles.answer}>{answer}</div>
															</div>
														);
													}
												})}
											</div>
										</div>
									)}
								</>
							)}
							{user?.role === "patient" && (
								<>
									{(
										<InfoBox
											className={styles.chatInfo}
											variant="warn"
											text={
												<>
                            <span>
                              Il tuo {user.therapyType === 'psychiatry' ? 'psichiatra' : 'terapeuta'} non sarà sempre online, ma ti risponderà il prima possibile. Ti ricordiamo che Mymentis non è un servizio di emergenza. Se ti trovi in una situazione di urgenza chiama uno
                            </span>{' '}
													<span
														onClick={() => window.location.href = `${appSiteUrl}/numeri-di-emergenza`}
														className={styles.chatInfoConsent}
													>
                              di questi numeri.
                            </span>
												</>
											}
										/>
									)}
									{(showGreyBlock && !showGreyBlockByUser) && (
										<InfoBox
											className={styles.chatInfo}
											closeButton
											closeModal={() => {
												setShowGreyBlock(false)
												localStorage.setItem('greyBlock', JSON.stringify(true));
											}}
											title={`Ciao, ${chats[0]?.patient.given_name}`}
											text={
												<>
													<span>Siamo felici di presentarti il terapeuta che abbiamo selezionato appositamente per te:</span>
													<div style={{fontWeight: '600'}}>{therapistName?.name} {therapistName?.surname}</div>
													<div style={{fontWeight: '600'}}>{myTherapist?.title}</div>
													<span>Ti invitiamo a inviare un messaggio in chat indicando i giorni e gli orari in cui sei disponibile per il colloquio gratuito. Sarà un'opportunità per raccontarti meglio e porre tutte le domande che desideri</span>
												</>
											}
										/>
									)}
                  {((!user?.informedConsent && (!showGreyBlock || showGreyBlockByUser))) && (
										<InfoBox
											className={styles.chatInfo}
											variant="warn"
											text={
												(therapistData?.therapyType ?? user?.therapyType) === 'underage' &&
												getinformedConsentResponse.data?.informedConsent &&
												!getinformedConsentResponse.data
													?.otherInformedConsentParent ? (
													<>
                            <span>
                              Hai compilato correttamente il consenso informato.
                              Per poter partecipare alle sedute il consenso deve
                              essere firmato anche dall'altro genitore, tramite
                              il link ricevuto sulla sua casella postale. Se non
                              hai ricevuto il link, puoi richiederne un altro
                              attraverso
                            </span>{" "}
														<span
															onClick={() => {
																navigate(
																	routes.ProfileSection.links.preferences
																);
															}}
															className={styles.chatInfoConsent}
														>
                              questa pagina.
                            </span>
													</>
												) : (
													<span>
                            Per proseguire è necessario compilare il consenso
                            informato.{" "}
														<span
															onClick={() => {
																getinformedConsentResponse
																	.refetch()
																	.then((response) => {
																		if (!response.data?.informedConsent) {
																			navigate(
																				routes.ProfileSection.links.preferences,
																				{
																					state: {
																						informedConsentIsOpen: true,
																					},
																				}
																			);
																		} else {
																			navigate(
																				routes.ProfileSection.links.preferences
																			);
																		}
																	})
																	.catch((e) => {
																		raiseException(e);
																		navigate(
																			routes.ProfileSection.links.preferences
																		);
																	});
															}}
															className={styles.chatInfoConsent}
														>
                              Clicca qui
                            </span>{" "}
														per compilarlo in pochi semplici passi
                          </span>
												)
											}
										/>
									)}
									<CurrentUserLocation/>
								</>
							)}
							{user?.role === 'therapist' && (patientDetailIsOpen || docSheetIsOpen || showDocuments) ? (
								<PatientDetail
									patient={patient}
									myTherapist={myTherapist}
									showDocuments={showDocuments}
									showSurveyAnswers={showSurveyAnswers}
									onGoToChat={() => setPatientDetailIsOpen(false)}
									chat={patientChat}
									getinformedConsentResponse={getinformedConsentResponse?.data}
								/>
							) : (
								<div
									data-test="messagesWrapper"
									className={styles.conversation}
									onScroll={(e) => {
										checkScrollToBottom(e);
									}}
								>
									<div ref={conversationTopRef}></div>
									{messagesList.length > 0
										? messagesList
											?.map((i: any, index: number) => {
												const sender = i.from === user?.id ? "me" : "other";
												const date = new Date(i.when);
												const previousDate = new Date(
													messagesList[index + 1]?.when
												);
												const hour = `${getNormalizedDate(i.when).toLocaleTimeString("it", {
													hour: "2-digit",
													minute: "2-digit",
												})}`;

												if (i.type === "text") {
													if (
														index > -1 &&
														(date.getDate() !== previousDate.getDate() ||
															date.getMonth() !== previousDate.getMonth() ||
															date.getFullYear() !==
															previousDate.getFullYear())
													) {
														return (
															<div
																id={i?.id}
																key={index}
																ref={(el: any) => {
																	if (messageWrapperRef.current !== null) {
																		messageWrapperRef.current[index] = el!;
																	}
																}}
															>
																<MessageWrapper
																	dateIsVisible={true}
																	sender={sender}
																	text={i.content}
																	date={dateToShow(date, "chat")!}
																	hour={hour}
																/>
															</div>
														);
													} else {
														return (
															<div
																id={i?.id}
																key={index}
																ref={(el: any) => {
																	if (messageWrapperRef.current !== null) {
																		messageWrapperRef.current[index] = el!;
																	}
																}}
															>
																<MessageWrapper
																	dateIsVisible={false}
																	sender={sender}
																	text={i.content}
																	date={dateToShow(date, "chat")!}
																	hour={hour}
																/>
															</div>
														);
													}
												} else if (i.type === "widget") {
													const {appointment} = i;
													const {
														startTime,
														endTime,
														status,
														isFirstAppointment,
														createdByPatient
													} = appointment;

													return (
														<div
															id={i?.id}
															key={index}
															ref={(el: any) => {
																if (messageWrapperRef.current !== null) {
																	messageWrapperRef.current[index] = el!;
																}
															}}
														>
															<MessageWrapper
																isWidget={true}
																dateIsVisible={false}
																sender={sender}
																text={
																	<div>
																		{status === "waiting_for_confirm" &&
																			+getNormalizedDate(startTime) > +getNormalizedDate() && (
																				<div
																					className={
																						styles.infoNewAppointment
																					}
																				>
																					<img
																						className={styles.icon}
																						src={celebration}
																						alt="celebration icon"
																					/>
																					Nuova proposta di appuntamento
																				</div>
																			)}
																		<AppointmentWidget
																			messageId={i.id}
																			isFirstAppointment={isFirstAppointment}
																			createdByPatient={createdByPatient}
																			userRole={user?.role}
																			onModify={onModify}
																			onAccept={onAccept}
																			onPatientPayment={() => onPatientPayment(appointment, i.id)}
																			onReady={() => onReady(appointment.id, isFirstAppointment)}
																			appointment={appointment}
																			endTime={endTime}
																			widgetStatus={status}
																			startTime={startTime}
																			therapyType={therapistData?.therapyType || user?.therapyType || patient?.therapyType}
																		/>
																	</div>
																}
																date={dateToShow(date, "chat")!}
																hour={hour}
															/>
														</div>
													);
												} else if (i.type === "file") {
													return (
														<div
															id={i?.id}
															key={index}
															ref={(el: any) => {
																if (messageWrapperRef.current !== null) {
																	messageWrapperRef.current[index] = el!;
																}
															}}
														>
															<MessageWrapper
																isWidget={true}
																dateIsVisible={false}
																sender={sender}
																text={
																	<div>
																		<FileWidget
																			filename={i.filename}
																			messageId={i.id}
																		/>
																	</div>
																}
																date={dateToShow(date, "chat")!}
																hour={hour}
															/>
														</div>
													);
												}
											})
											.reverse()
										: !messagesToggle && (
										<div className={styles.emptyChat}>
											{actualChat?.deletedPatientName ? 'Il paziente ha deciso di eliminare il suo profilo' : 'Non ci sono ancora messaggi'}
										</div>
									)}
									<div ref={conversationBottomRef}></div>
								</div>
							)}
							{!patientDetailIsOpen && !actualChat?.deletedPatientName && !docSheetIsOpen && !showDocuments && (
								<TextAreaInput
									messagesList={messagesList}
									oldMessageDateIsVisible={oldMessageDateIsVisible}
									scrollToBottom={scrollToBottom}
									sendMessage={sendMessage}
									value={message}
									onChange={setMessage}
									chatId={patientChat?.id}
									appointmentAction={setAppointment}
									onModify={showInformModal}
									appointmentIcon={user?.role === "therapist"}
									user={user}
								/>
							)}
						</div>
					);
				} else {
					return (
						<div
							className={`${styles.chat} ${
								!patientChatIsOpen &&
								user?.role === "therapist" &&
								styles.hideChat
							}`}
						>
							<div className={styles.emptyChat}>Selezionare un paziente</div>
						</div>
					);
				}
			})()}
		</div>
	);
};
export default ChatRoom;
