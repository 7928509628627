import { useState } from 'react';
import styles from "./Tabs.module.scss";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface Item {
  key: number | string;
  label: any;
  active?: boolean;
  content: any;
  disabled?: boolean;
}

interface Props {
  items: Item[];
  className?: string;
  labelClassName?: string;
  activeLabelClassName?: string;
  indexKey?: any;
  tab?: any;
  actTab?: any;
  activeClassName?: string;
}

export const Tabs = ({
  items,
  className = "",
  labelClassName = "",
  activeLabelClassName = "",
  indexKey,
  activeClassName
}: Props) => {
  const [activeTab, setActiveTab] = useState<any>(indexKey ? parseInt(indexKey) : 1);

  const selectTab = (tabKey: any) => {
    setActiveTab(tabKey);
  };

  return (
    <div className={`${styles.tabs} ${className}`}>
      <div className={`${styles.label} ${labelClassName}`}>
        {items.map((i: any, index: number) => {
          return (
            <div
              key={index}
              className={`${
                i.key === activeTab
                  ? `${styles.active} ${activeLabelClassName}`
                  : styles.tabHeader
              } ${i.disabled && styles.disabled}`}
              onClick={() => !i.disabled && selectTab(i.key)}
            >
              {i.label === 'Informazioni' ?
                <div className={styles.tabLabel}>
                  <div className={styles.icon}>
                    <InformationCircleIcon/>
                  </div>
                  <span>{i?.label}</span>
                </div> :
                i?.label
              }
            </div>
          );
        })}
      </div>
      <div className={`${styles.activeTab} ${activeClassName} ${activeTab === 3 ? styles.booking : ''}`}>
        {items.find((item) => item.key === activeTab)?.content}
      </div>
    </div>
  );
};
