import Button from "components/atoms/Button";
import InfoBox from "components/atoms/InfoBox";
import { Fragment, useEffect, useState } from "react";
import { trpc } from "utils/trpc";
import styles from "./TherapistPayment.module.scss";
import { generateProformaPdf, unitCostEnpapCount } from "utils/general";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { analyticsService } from "../../../utils/analyticsService";
import { raiseException } from "../../../utils/raiseException";
import { useTherapistData } from '../../../customHooks/therapistDataContext';

export const TherapistPayment = () => {
  const [stripeExpressAccountActive, setStripeExpressAccountActive] =
    useState<boolean>();
  const [avaiableBalance, setAvaiableBalance] = useState<number>(0);
  const [paymentList, setPaymentList] = useState<any[]>([]);
  const [stripeLoading, setStripeLoading] = useState(true);
  const [stripeError, setStripeError] = useState(false);
  const [therapyTypes, setTherapyTypes] = useState<any[]>([]);
  const {therapistData} = useTherapistData();

  useEffect(() => {
    analyticsService({
      'event': 'virtualPageview',
      'pageUrl': '/dashboard/profilo/pagamenti',
      'pageTitle': 'Therapist Payment Methods'
    });
  }, []);

  const {
    data: userInfo,
    refetch: refetchUserInfo,
    isError: isErrorUserInfo,
  } = trpc.mainService.user.getCurrentUserInfo.useQuery();
  const getStripeOnBoard =
    trpc.mainService.therapist.getStripeConnectOnboardingUrl.useMutation();
  const getStripeDashboard =
    trpc.mainService.therapist.getStripeConnectAccountUrl.useMutation();
  const getMonthlyPayments =
    trpc.mainService.therapist.getMonthlyPayments.useQuery().data;
  const billingDetails =
    trpc.mainService.therapist.getBillingDetails.useQuery().data;

  const [stripeUrlLoading, setStripeUrlLoading] = useState(false);

  const stripeOnBoard = async () => {
    const result = await getStripeOnBoard.mutateAsync();
    return result.link.url;
  };

  const stripeDashboard = async () => {
    const result = await getStripeDashboard.mutateAsync();
    return result.link.url;
  };

  useEffect(() => {
    if (
      userInfo?.user?.stripeExpressAccountActive === undefined ||
      userInfo?.user?.stripeExpressAccountActive === null
    ) {
      return;
    }

    if (isErrorUserInfo) {
      setStripeError(true);
    }

    if (userInfo) {
      setStripeLoading(false);
    }
  }, [userInfo?.user?.stripeExpressAccountActive]);

  useEffect(() => {
    if (userInfo) {
      setStripeExpressAccountActive(userInfo.user.stripeExpressAccountActive!);
      setTherapyTypes(userInfo.user.therapies!);
    }
  }, [userInfo]);

  useEffect(() => {
    if (getMonthlyPayments && therapyTypes) {
      const occurences: { [key: string]: number } = {};

      getMonthlyPayments.appointments[0]?.transactions.forEach(
        (transaction) => {
          if (occurences[transaction.amount]) {
            occurences[transaction.amount] += 1;
          } else {
            occurences[transaction.amount] = 1;
          }
        }
      );

      let total = Object.entries(occurences)
        .map(([unitCostKey, numberOfAppointments]) => {
          const unitCost = Number(unitCostKey);
          const unitCostWithoutEnpap = unitCostEnpapCount(unitCost, therapyTypes);
          const unitWithHoldingTax =
            Math.floor(unitCostWithoutEnpap * 0.2 * 100) / 100;

          let finalUnitCost: number;
          if (userInfo?.user?.vatNumberType === "ordinaria") {
            finalUnitCost = unitCost - unitWithHoldingTax;
          } else {
            finalUnitCost = unitCost;
          }

          return finalUnitCost * numberOfAppointments;
        })
        .reduce((a, b) => a + b, 0);

      setPaymentList(getMonthlyPayments.appointments);
      setAvaiableBalance(total);
    }
  }, [getMonthlyPayments, therapyTypes]);

  return (
    <div className={styles.therapistPayment}>
      {stripeError ? (
        <div className={styles.loadingSpinner}>
          <p>
            Si è verificato un errore durante il caricamento delle informazioni.{" "}
            <span
              onClick={async () => {
                setStripeLoading(true);
                setStripeError(false);
                const response = await refetchUserInfo();
                if (
                  response?.data?.user?.stripeExpressAccountActive === undefined ||
                  response?.data?.user?.stripeExpressAccountActive === null
                ) {
                  setStripeLoading(false);
                  setStripeError(true);
                  return;
                }
              }}
            >
              Clicca qui
            </span>{" "}
            per riprovare
          </p>
        </div>
      ) : stripeLoading ? (
        <div className={styles.loadingSpinner}>
          <Spin
            className={styles.spinner}
            indicator={
              <LoadingOutlined
                color={"#3576bb"}
                style={{ fontSize: 32 }}
                spin
              />
            }
          />
        </div>
      ) : (
        <Fragment>
          <div className={`${styles.title} ${styles.mainTitle}`}>Pagamenti</div>
          <div className={styles.paymentContainer}>
            <div className={styles.topPart}>
              <div className={styles.availableBalance}>
                <span>Saldo Disponibile</span>
                <h6>
                  {Number.isNaN(avaiableBalance)
                    ? "0.00 €"
                    : `${avaiableBalance.toFixed(2)} €`}
                </h6>
              </div>
              {!stripeExpressAccountActive && (
                <InfoBox
                  className={`${styles.infoBox} ${styles.infoBoxWarning} ${styles.infoBoxWarningNote}`}
                  text={
                    <div className={styles.infoWithLink}>
                      Il tuo account Stripe Connect non risulta ancora completo.
                      Accedi cliccando il link sottostante per completarlo con
                      le informazioni mancanti. <br />
                      L'aggiornamento potrebbe richiedere fino a un minuto.
                      Ricarica la pagina per controllare.
                    </div>
                  }
                />
              )}
              {userInfo?.user && (
                <InfoBox
                  className={`${styles.infoBox} ${styles.infoBoxWarning}`}
                  text={
                    <div className={styles.infoWithLink}>
                      Il "saldo disponibile" fa riferimento al totale del mese
                      precedente. Per visualizzare tutti gli incassi in tempo
                      reale consulta il tuo account{" "}
                      <span
                        onClick={() => {
                          if (stripeUrlLoading) {
                            return;
                          }
                          setStripeUrlLoading(true);
                          if (userInfo.user.stripeExpressAccountActive) {
                            stripeDashboard()
                              .then((url) => {
                                setStripeError(false);
                                setStripeUrlLoading(false);
                                // @ts-ignore
                                window.location = url;
                              })
                              .catch((e) => {
                                raiseException(e);
                                setStripeError(true);
                                setStripeUrlLoading(false);
                              });
                          } else {
                            stripeOnBoard()
                              .then((url) => {
                                setStripeError(false);
                                setStripeUrlLoading(false);
                                // @ts-ignore
                                window.location = url;
                              })
                              .catch((e) => {
                                raiseException(e);
                                setStripeError(true);
                                setStripeUrlLoading(false);
                              });
                          }
                        }}
                      >
                        Stripe connect.
                      </span>
                    </div>
                  }
                />
              )}
            </div>
            <div className={styles.bottomPart}>
              <div className={styles.paymentHeader}>
                <h5 className={styles.title}>I tuoi saldi</h5>

                <span className={styles.message}>
                  Scarica il modello precompilato al fine di creare la fattura
                  elettronica e ricevere il tuo compenso. Per finalizzare il
                  pagamento dovremo ricevere correttamente la fattura nel
                  cassetto fiscale tramite SDI.
                </span>
                {/* <div className={styles.myMentisInvoicing}>
              Dati fatturazione Mymentis
            </div> */}
              </div>
              <div className={styles.paymentList}>
                {paymentList.map((payment, key) => {
                  const year = payment.year;
                  const month = new Date(
                    new Date().setMonth(payment.month)
                  ).toLocaleDateString("it", { month: "long" });
                  const numberOfAppointments = payment.transactions.length;

                  const occurences: { [key: string]: number } = {};

                  payment.transactions.forEach((transaction) => {
                    if (occurences[transaction.amount]) {
                      occurences[transaction.amount] += 1;
                    } else {
                      occurences[transaction.amount] = 1;
                    }
                  });

                  let totalPeriodPayment = Object.entries(occurences)
                    .map(([unitCostKey, numberOfAppointments]) => {
                      const unitCost = Number(unitCostKey);
                      const unitCostWithoutEnpap = unitCostEnpapCount(unitCost, therapyTypes);
                      const unitWithHoldingTax =
                        Math.floor(unitCostWithoutEnpap * 0.2 * 100) / 100;

                      let finalUnitCost: number;
                      if (userInfo?.user?.vatNumberType === "ordinaria") {
                        finalUnitCost = unitCost - unitWithHoldingTax;
                      } else {
                        finalUnitCost = unitCost;
                      }

                      return finalUnitCost * numberOfAppointments;
                    })
                    .reduce((a, b) => a + b, 0);

                  return (
                    <div key={key} className={styles.paymentCard}>
                      <div className={styles.paymentPeriod}>
                        {month} {year} ({numberOfAppointments} sedut
                        {numberOfAppointments === 1 ? "a" : "e"})
                      </div>
                      <div className={styles.paymentDetail}>
                        <div className={styles.paymentAmmount}>
                          <span>Importo</span>€ {totalPeriodPayment.toFixed(2)}
                        </div>
                        <Button
                          onClick={() => {
                            generateProformaPdf({
                              transactions: payment.transactions,
                              // totalAppointmentsCost: total,
                              numberOfAppointments,
                              totalPeriodPayment,
                              vatNumberType: userInfo?.user.vatNumberType!,
                              month: parseInt(payment.month),
                              year: payment.year,
                              address: billingDetails?.billingDetails.address!,
                              iban: billingDetails?.billingDetails.iban!,
                              name: billingDetails?.billingDetails.given_name!,
                              surname:
                                billingDetails?.billingDetails.family_name!,
                              taxId: billingDetails?.billingDetails.taxId!,
                              vatNumber:
                                billingDetails?.billingDetails.vatNumber!,
                              therapyType: therapyTypes
                            });
                          }}
                        >
                          Scarica Modello
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};
